import { useEffect, useState } from "react"
import TTSSidebar from "./TTSSidebar"
import BootstrapTable from "react-bootstrap-table-next"
import axios from "axios";
import DeleteLexiPopUp from "./DeleteLexiPopUp";
import CreateLexiModal from "./CreateLexiModal";
import { imsLoader,RenameIcon } from "../../assets";
import RenameLexi from "./RenameLexi";
const AllLexicon = () => {
    useEffect(() => {
        setLoader(true);
        FetchLexiconData();
    }, [])
    const [data1, setData] = useState([]);
    const [displayUpload, setDisplayUpload] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [row, setRow] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loader ,setLoader] = useState(false);
    const [newLexicon, setNewLexicon] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [rename ,setRename] = useState(false)

    useEffect(() => {
        if (refresh === true) {
            setLoader(true);
            FetchLexiconData();
        }
    }, [refresh])
    useEffect(() => {
        handleCheck(isChecked, row);
    }, [row, isChecked])

    const FetchLexiconData = async () => {
        await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=select l.id , l.name, count(r.lexiconid) as no_of_phoneme from "iMS".phm_ims_ttslexicon l LEFT JOIN "iMS".phm_ims_ttslexiconphonemerel r ON l.id = r.lexiconid GROUP BY l.id`).then((response) =>
            setData(response.data.rows)).catch((error) => console.log(error));
        setLoader(false)
    }

    const handleCheck = (checked, row1) => {
        if (checked === true) {
            setSelectedRows(selectedRows.concat(row));
        }
        else if (checked === false) {
            let removeRow = selectedRows.filter((val) => {
                return val.name !== row.name
            })
            setSelectedRows(removeRow)
        }
    }
    const handleDelete =  () => {
        setRefresh(false)
        if(selectedRows.length > 0){
        setDeleteModal(true)
        }
    }
    const handleRename = () =>{
        setRefresh(false)
         setRename(true)
    }

    let columns = [
        {
            headerStyle: {
                paddingTop: '5px',
                width: "5%",
            },
            style: {
                padding: "2px"
            },
            hidden: false,
            dataField: "folderid",
            formatter: (cell, row) => {
                return <div style={{ marginTop: "10px" }}><input type="checkbox" id={row} name="row" value={row} onChange={(e) => {
                    setRow(row)
                    setIsChecked(e.target.checked);
                }} /></div>
            },
        },
        {
            dataField: 'name',
            text: 'Name',
            editable: false,
            headerStyle: {
                width: "40%"
            },
            style: {
                padding: "2px"
            },
        },
        {
            dataField: 'no_of_phoneme',
            text: 'No of Phoneme',
            editable: false,
            headerStyle: {
                width: '35%',
                height: "1%"
            },
            style: {
                padding: "2px"
            },
        },
        {
            dataField: "id",
            text: "Lexicon Id",
            editable: false,
            headerStyle: {
                width: "35%"
            },
            style: {
                padding: "2px"
            },
        }
    ]
    return (<>
        <div className="TTS-mytask-contents-wrapper" >
            <TTSSidebar />
            <div className="right-panel"  >
                <div className="WNFY-WOAV-RV-QH-outer-wrapper">
                    <div className="WNFY-WOAV-outer-wrapper">
                        <h1 className="intro-text">Manage Lexicon Files
                        </h1>
                        <div className="TSS-container-header">
                            <div>
                                <button className={(displayUpload) ? "TSS-button-style-selected" :
                                    "TSS-button-style-active"} onClick={() => {
                                        setRefresh(false)
                                        setNewLexicon(true)}}
                                //disabled={(selected.length <= 1) ? false : true}
                                >
                                    <span
                                        className="TSS-container-header-span">
                                        <svg width="20px" height="20px" viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Dribbble-Light-Preview" transform="translate(-379.000000, -240.000000)" fill="#3276c3">
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <polygon id="plus-[#1512]" points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89">
                                                        </polygon>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="TSS-label-text"
                                        >New</span>
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button className="TSS-button-style-active">
                                    <span className="TSS-container-header-span">
                                        <svg id="delete" xmlns="http://www.w3.org/2000/svg" width="11.274" height="16.156" viewBox="0 0 11.274 16.156" fill={"#0074b0"}>
                                            <g id="Group_23297" data-name="Group 23297" transform="translate(0)">
                                                <g id="Group_23296" data-name="Group 23296" transform="translate(0)">
                                                    <path id="Path_11289" data-name="Path 11289" d="M64,95a1.61,1.61,0,0,0,1.611,1.611h6.443A1.61,1.61,0,0,0,73.664,95V85.333H64Z" transform="translate(-63.195 -81.201)" fill="none" stroke={"#0074b0"} stroke-width="1.5" />
                                                    <path id="Path_11290" data-name="Path 11290" d="M51.123.805,50.317,0H46.291l-.805.805H42.667V2.416H53.941V.805Z" transform="translate(-42.667)" fill="#0074b0" />
                                                </g>
                                            </g>
                                        </svg>

                                        <span className="TSS-label-text"
                                            onClick={() => { handleDelete() }}
                                        >Delete</span>
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button className="TSS-button-style-active"
                                disabled={(selectedRows.length === 1) ? false : true}>
                                    <span className="TSS-container-header-span">
                                        <img src = {RenameIcon}/>
                                        <span className="TSS-label-text"
                                            onClick={() => { handleRename() }}
                                        >Rename</span>
                                    </span>
                                    </button>
                            </div>
                        </div>
                        <div className="main-content-container"
                            style={{ padding: "10px", width: "75%" }}
                        >
                            {(loader)? <img style ={{marginLeft:'460px' ,width:'100px'}} src={imsLoader}/>:<div className="Table-CXonatainer"
                                style={{
                                    height: "60%",
                                    marginTop: "5px",
                                }}>
                                <BootstrapTable
                                    className="tableElement"
                                    keyField="name"
                                    data={data1}
                                    columns={columns}
                                />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(newLexicon) && <CreateLexiModal
            setRefresh = {()=>setRefresh(true)}
            showModal = {newLexicon}
            handleClose={()=>setNewLexicon(false)}/>}
        {(deleteModal) && <DeleteLexiPopUp
            phonemeDelete={false}
            id={selectedRows[0]?.id}
            showModal={deleteModal}
            handleClose={() => {
                setRefresh(true)
                setDeleteModal(false)
            }
            } />}
            {(rename) && <RenameLexi
            selectedId={selectedRows[0].id}
            showModal = {true}
            handleClose = {()=>{
                setRename(false)
                setRefresh(true)
                setSelectedRows([])
            }}/>}
    </>)
}
export default AllLexicon