import Services from "./services";
import axios from "axios";
import { UtilityServices, LocationOrigin } from "./utilityServices";


export default class ApiServices {
    services = new Services();
    getAllRegions = async () => {
        let dbQuery = `select  distinct(region), regionid from "iMS".phm_ims_region order by regionid asc`;
        var regions = await this.services.getService(null, dbQuery);
        return regions;
    };
    getAllCountries = async () => {
        let dbQuery = `select country,region from "iMS".phm_ims_region`;
        var countries = await this.services.getService(null, dbQuery);
        return countries;
    };
    getUserDetails = async (wwid) => {
        let dbQuery = `select * from "iMS".phm_ims_userdetails where wwid = '${wwid}'`;
        var details = await this.services.getService(null, dbQuery);
        return details[0];
    };
    getRegistrationDetails = async (wwid) => {
        let dbQuery = `select * from "iMS".phm_ims_registrationdetails where wwid = '${wwid}'`;
        var details = await this.services.getService(null, dbQuery);
        return details[0];
    };
    UpdateCurrentdepartment = async (value, wwid) => {
        value = value.replace(/'/g, "''");
        let dbQuery = `UPDATE "iMS".phm_ims_userdetails
        SET currentdepartment='${value}'
        WHERE wwid='${wwid}'`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    }
    getAllTAreas = async () => {
        let dbQuery = `select  distinct(taname), taid from "iMS".phm_ims_products order by taid asc`;
        var tAreas = await this.services.getService(null, dbQuery);
        return tAreas;
    };
    getAllProducts = async () => {
        let dbQuery = `select taid,taname,classid,classname,productid,productname from "iMS".phm_ims_products`;
        var products = await this.services.getService(null, dbQuery);
        return products;
    };
    getAllDocuments = async () => {
        let dbQuery = `select * from "iMS".phm_ims_documentTypes order by doctype asc`;
        var docs = await this.services.getService(null, dbQuery);
        return docs;
    };
    getAllUsersDetails = async () => {
        //let dbQuery = `select * from "iMS".phm_ims_registrationdetails order by modified_date desc`;
        let dbQuery = `select piu.accessgroup, piu.currentdepartment, pir.*  from "iMS".phm_ims_registrationdetails pir inner join "iMS".phm_ims_userdetails piu on pir.wwid = piu.wwid order by pir.modified_date desc`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    // getUsersDetailsByWwid = async (wwid) => {
    //     let dbQuery = `select * from "iMS".phm_ims_registrationdetails where wwid = ${wwid}`;
    //     var res = await this.services.getService(null, dbQuery);
    //     return res;
    // };
    getUserRegistrationDetailsByWwid = async (wwid) => {
        let dbQuery = `select  pir.*,piu.accessgroup  from "iMS".phm_ims_registrationdetails pir inner join "iMS".phm_ims_userdetails piu on pir.wwid = piu.wwid where pir.wwid = ${wwid}`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAllCountriesByRegions = async (regions) => {
        let dbQuery = `select distinct (country) FROM "iMS".phm_ims_region where country notnull and country!='' and regionid in (${regions}) order by country`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAllDocumentTypes = async () => {
        let dbQuery = `SELECT  distinct (doctype) FROM "iMS".phm_ims_documenttypes where doctype notnull and doctype!='' order by doctype`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAllTherapeuticAreas = async () => {
        let dbQuery = `SELECT distinct (taid), taname FROM "iMS".phm_ims_products order by taname`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAllDrugClasses = async (taids) => {
        let dbQuery = `SELECT  distinct (classid), classname,taid FROM "iMS".phm_ims_products where taid in(${taids})`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAllProductsByClassids = async (classids) => {
        let dbQuery = `SELECT  distinct (productid), productname,classid FROM "iMS".phm_ims_products where classid in('${classids}')`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAdminDetails = async (userid) => {
        let dbQuery = `select "iMS".phm_ims_isAdminfunc('${userid}')`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getJJEDSdetails = async (userid) => {
        var details = await this.services.getJJEDSservice(userid);
        return details;
    };
    getUserProductAndWwid = async (wwid) => {
        let dbQuery = `select wwid,products from "iMS".phm_ims_registrationdetails where wwid = '${wwid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getDefaultProduct = async (userid) => {
        let dbQuery = `select default_product from "iMS".phm_ims_default_user_product where email = '${userid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getUserPreference = async (wwid) => {
        let dbQuery = `select regions,products,contentprefernce,documenttypes from "iMS".phm_ims_registrationdetails where wwid = '${wwid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res[0];
    };
    getUserPreferenceFromUserDetails = async (wwid) => {
        let dbQuery = `select region,products,contentprefernce,docs  from "iMS".phm_ims_userdetails where wwid = '${wwid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res[0];
    };
    getUserStatus = async (wwid) => {
        let dbQuery = `select status,regions,profession from "iMS".phm_ims_registrationdetails where wwid = '${wwid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res[0];
    };
    getUserApprovedRegionRole = async (wwid) => {
        let dbQuery = `select region,function from "iMS".phm_ims_userdetails where wwid = '${wwid}'`;
        var res = await this.services.getService(null, dbQuery);
        return res[0];
    };
    sendEmail = async (data) => {
        let url =
            LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + "sendemail/";
        var res = await this.services.postService(url, data);
        return res;
    };
    updateDbService = async (dbQuery) => {
        var res = await this.services.putService(dbQuery);
        return res;
    };

    insertupdateGlobalProduct = async (wwid, mailId, product) => {
        let dbQuery = `call "iMS".phm_ims_addeditglobalproduct(${wwid},'${mailId.toLowerCase()}','${product}')`;
        await this.services.putService(dbQuery);
    };
    getDosagesforProduct = async (product) => {
        let dbQuery = `select * from "iMS".phm_ims_dosage where product='${product}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getCongressforProduct = async (product) => {
        let dbQuery = `select * from "iMS".phm_ims_keycongresses where product='${product}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getCompetitorDrugsforProduct = async (product) => {
        let dbQuery = `select * from "iMS".phm_ims_competitordrugs where product='${product}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getPreviewData = async (docid) => {
        let dbQuery = `select pageimage from "iMS".phm_ims_docfirstpage where search_id = '${docid}'`;
        var res = await this.services.getService(null, dbQuery);
        if (res && res.length > 0 && res[0].pageimage && res[0].pageimage.data) {
            return res[0].pageimage.data;
        } else {
            return "";
        }
    };
    getPreview = async (searchId) => {
        let dbQuery = `select count(distinct page_number) as page_count from "iMS".phm_ims_previewdata where search_id = '${searchId}'`;
        var res = await this.services.getService(null, dbQuery);
        return res[0];
    };
    getSearchKeywordCountForPreview = async (searchId) => {
        let dbQuery = `select page_number,page_text from "iMS".phm_ims_previewdata where search_id = '${searchId}' order by page_number`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getBannerMessage = async () => {
        let dbQuery = `SELECT id, message, is_visible FROM "iMS".phm_ims_bannermessage WHERE is_visible=true`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    insertUpdateReadingList = async (rlName, rlId, username, docid) => {
        rlName = rlName.replace(/'/g, "''");
        let dbQuery = `call "iMS".phm_ims_addupdatereadingList('${rlId}','${rlName}','${docid}','${username}')`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    insertUpdateNotebookList = async (loginid, notebooklistid, notebooklistname, originaltext, summarizedtext, referencetext, notestext, tableSummarizedHtml, commandType, commandText, docTitle, url) => {
        notebooklistname = notebooklistname.replace(/'/g, "''");
        originaltext = originaltext.replace(/'/g, "''");
        summarizedtext = summarizedtext.replace(/'/g, "''");
        referencetext = referencetext.replace(/'/g, "''");
        notestext = notestext.replace(/'/g, "''");
        tableSummarizedHtml = tableSummarizedHtml.replace(/'/g, "''");
        commandType = commandType.replace(/'/g, "''");
        commandText = commandText.replace(/'/g, "''");
        docTitle = docTitle.replace(/'/g, "''");
        url = url.replace(/'/g, "''");
        let dbQuery = `call "iMS".phm_ims_addupdatenotebooklistv2(${loginid},'${notebooklistid}','${notebooklistname}','${originaltext}','${summarizedtext}','${referencetext}','${notestext}','${tableSummarizedHtml}','${commandType}','${docTitle}','${url}','${commandText}')`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    insertCommandList = async (loginid, command_name, command_description, command_text) => {
        command_name = command_name.replace(/'/g, "''");
        command_description = command_description.replace(/'/g, "''");
        command_text = command_text.replace(/'/g, "''");
        let dbQuery = `INSERT INTO "iMS".phm_ims_usercommand
        (login_id, command_name, command_description, command_text, added_on)
        VALUES(${loginid}, '${command_name}', '${command_description}', '${command_text}', now());
        `;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    updateCommand = async (cID, command_name, command_description, command_text) => {
        command_name = command_name.replace(/'/g, "''");
        command_description = command_description.replace(/'/g, "''");
        command_text = command_text.replace(/'/g, "''");
        let dbQuery = `update "iMS".phm_ims_usercommand set 
        command_name='${command_name}',
        command_description='${command_description}',
        command_text='${command_text}'
         where id = ${cID}`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    insertPromptTemplateList = async (loginid, template_name, prompt) => {
        template_name = template_name.replace(/'/g, "''");
        prompt = prompt.replace(/'/g, "''");
        let dbQuery = `INSERT INTO "iMS".phm_ims_user_prompt_templates(login_id, template_name, prompt, added_on) VALUES(${loginid}, '${template_name}', '${prompt}', now());`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    insertUserPromptTemplateList = async (loginid, sptid) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_user_prompt_templates (login_id, template_name, prompt, added_on)
                        SELECT '${loginid}',template_name, prompt, now() 
                        FROM "iMS".phm_ims_system_prompt_templates spt 
                        WHERE spt.id  = ${sptid};`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    updatePromptTemplate = async (cID, template_name, prompt) => {
        template_name = template_name.replace(/'/g, "''");
        prompt = prompt.replace(/'/g, "''");
        let dbQuery = `UPDATE "iMS".phm_ims_user_prompt_templates
                        SET template_name='${template_name}', prompt='${prompt}'
                        WHERE  id = ${cID};`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    updateTemplateName = async (cID, template_name) => {
        template_name = template_name.replace(/'/g, "''");
        let dbQuery = `UPDATE "iMS".phm_ims_user_prompt_templates
                        SET template_name='${template_name}'
                        WHERE  id = ${cID};`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    insertUpdateAssistantFeedback = async (feedbackID, question, answer, documentnumber, doctitle, downloadurl, reaction, previousReaction, searchID, sectionanswer, sessionID, wwid) => {
        question = question.replace(/'/g, "''");
        answer = answer.replace(/'/g, "''");
        documentnumber = documentnumber.replace(/'/g, "''");
        doctitle = doctitle.replace(/'/g, "''");
        downloadurl = downloadurl.replace(/'/g, "''");
        sectionanswer = sectionanswer.replace(/'/g, "''");
        let dbQuery = `call "iMS".phm_ims_addupdateassistantfeedback('${feedbackID}','${question}','${answer}','${documentnumber}','${doctitle}','${downloadurl}','${reaction}','${searchID}','${sectionanswer}','${sessionID}',${wwid})`;
        let res = await this.services.putService(dbQuery);
        return res;
    };
    getAssistantfeedback = async (feedbackid) => {
        let dbQuery = `SELECT doctitle, downloadurl, searchid, sectionanswer, documentnumber, updated_on, feedbackid, id, reaction
        FROM "iMS".phm_ims_assistant_recommendations where feedbackid='${feedbackid}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    pushRecommendations = async (_dbQuery) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_assistant_recommendations
        (doctitle, downloadurl, searchid, sectionanswer, documentnumber, updated_on, feedbackid, reaction)VALUES${_dbQuery}`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    };
    updateRecommendationsReaction = async (feedbackID, searchID, reaction) => {
        let dbQuery = `UPDATE "iMS".phm_ims_assistant_recommendations
        SET reaction=${reaction}
        WHERE searchid='${searchID}' and feedbackid='${feedbackID}'`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    };

    getReadingLists = async (username) => {
        let dbQuery = `select * from "iMS".phm_ims_readinglist where username = '${username}' order by added_on DESC`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    getDocIds = async (rlID) => {
        let dbQuery = `select distinct(doc_id) from "iMS".phm_ims_readinglistdocids where readinglist_id = '${rlID}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    updateReadingListName = async (rlID, rlName) => {
        rlName = rlName.replace(/'/g, "''");
        rlName = encodeURIComponent(rlName);
        let dbQuery = `update "iMS".phm_ims_readinglist set readinglist_name='${rlName}' where readinglist_id = '${rlID}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    updateNotebookName = async (nID, nName) => {
        nName = nName.replace(/'/g, "''");
        nName = encodeURIComponent(nName);
        let dbQuery = `update "iMS".phm_ims_notebooklist set notebooklist_name='${nName}' where notebooklist_id = '${nID}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    updateCommandName = async (cID, cName) => {
        cName = cName.replace(/'/g, "''");
        cName = encodeURIComponent(cName);
        let dbQuery = `update "iMS".phm_ims_usercommand set command_name='${cName}' where id = ${cID}`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    updateNotebookItems = async (id, value, column) => {
        value = value.replace(/'/g, "''");
        value = encodeURIComponent(value);
        let dbQuery = `update "iMS".phm_ims_notebooklistitems SET ${column.dataField}='${value}' where id = ${id}`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    insertEmailDocument = async (
        wwid,
        username,
        htmldocument,
        dateAdded,
        dateModified,
        searchId
    ) => {
        let doc = `<!DOCTYPE html><html><head><meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1.0'></head><body>${htmldocument.replace(
            /\\"/g,
            "'"
        )}</body></html>`;
        // console.log(doc,"doc")
        let payload = {
            wwid: wwid,
            username: username,
            htmldocument: doc,
            date_added: dateAdded,
            date_modified: dateModified,
            search_id: searchId,
        };
        await axios.post(
            "https://predev-ims.jnj.com/api/db/emlst",
            payload
        );
    };
    getEmailDocument = async (id) => {
        let dbQuery = `select * from "iMS".phm_ims_emaildocument where search_id = '${id}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getEmailDocumentById = async (id) => {
        let dbQuery = `select search_id,date_added from "iMS".phm_ims_emaildocument where username = '${id}'`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    deleteEmailDocumentBy = async (id, sid) => {
        let dbQuery = `delete from "iMS".phm_ims_emaildocument where username = '${id}' and search_id = '${sid}'`;
        await this.services.getService(null, dbQuery);
    };
    getAllAlertsProducts = async () => {
        let dbQuery = `select * from "iMS".phm_ims_alertsProduct where alert_type ='Product' order by product_name`;
        var products = await this.services.getService(null, dbQuery);
        return products;
    };
    getAllAlertsCCDSAndUSLabeling = async () => {
        let dbQuery = `select * from "iMS".phm_ims_alertsProduct where alert_type ='CCDS_And_USLabeling' order by product_name`;
        var products = await this.services.getService(null, dbQuery);
        return products;
    };
    createAlert = async (
        userId,
        alertKeyword,
        alertName,
        searchProprety,
        alertFrequency,
        emailId,
        currentDate,
        prodId,
        isProductAlert
    ) => {
        alertKeyword = alertKeyword.replace(/'/g, "''");
        alertName = alertName.replace(/'/g, "''");
        searchProprety = searchProprety.replace(/'/g, "''");
        let dbQuery = `insert into "iMS".phm_ims_manageAlerts (login_id, search_keyword, search_property, search_name, alert_enabled, alert_frequency, email_id, search_desc, date_modified, product_id, isProduct_alert) values ('${userId}', '${alertKeyword}', '${searchProprety}', '${alertName}', 1, '${alertFrequency}', '${emailId}', '${searchProprety}', '${currentDate}', ${prodId}, ${isProductAlert})`;
        await this.services.postServiceForDb(dbQuery);
    };
    getProductsAlertsById = async (userId) => {
        let dbQuery = `select * from "iMS".phm_ims_manageAlerts where email_id = '${userId}'`;
        var productsAlerts = await this.services.getService(null, dbQuery);
        return productsAlerts;
    };
    deleteReadingLists = async (readinglist_id, username) => {
        let dbQuery = `call "iMS".phm_ims_deletereadinglist('${readinglist_id}','${username}')`;
        await this.services.putService(dbQuery);
    };
    deleteNotebookLists = async (notebooklistid, loginid) => {
        let dbQuery = `call "iMS".phm_ims_deletenotebooklist('${notebooklistid}',${loginid})`;
        await this.services.putService(dbQuery);
    };
    deleteUserCommandLists = async (commandid, loginid) => {
        let dbQuery = `DELETE FROM "iMS".phm_ims_usercommand WHERE id= ${commandid} and login_id = '${loginid}'`;
        return await this.services.getService(null, dbQuery);
    };
    deleteUserPromptTemplatesLists = async (commandid, loginid) => {
        let dbQuery = `DELETE FROM "iMS".phm_ims_user_prompt_templates WHERE id= ${commandid} and login_id = '${loginid}'`;
        return await this.services.getService(null, dbQuery);
    };
    unsubscribeProductAlerts = async (userId, prodId) => {
        let dbQuery = `delete from "iMS".phm_ims_manageAlerts where email_id = '${userId}' and product_id = ${prodId}`;
        var productsAlerts = await this.services.getService(null, dbQuery);
        return productsAlerts;
    };
    deletePersonalAlerts = async (userId, alertName) => {
        alertName = alertName.replace(/'/g, "''");
        alertName = encodeURIComponent(alertName);
        let dbQuery = `delete from "iMS".phm_ims_manageAlerts where email_id = '${userId}' and search_name = '${alertName}'`;
        var personalAlerts = await this.services.getService(null, dbQuery);
        return personalAlerts;
    };
    updatePersonalAlerts = async (
        alertStatus,
        alertFrequency,
        userId,
        alertName
    ) => {
        alertName = alertName.replace(/'/g, "''");
        alertName = encodeURIComponent(alertName);
        let dbQuery = `update "iMS".phm_ims_manageAlerts set alert_enabled=${alertStatus}, alert_frequency='${alertFrequency}' where email_id = '${userId}' and search_name = '${alertName}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    updateDocIdFromReadingList = async (
        docIDsNow,
        readingListId,
        docIdsPrev = []
    ) => {
        let dbQuery = `update "iMS".phm_ims_ReadingListDocIDs set doc_id='${docIDsNow}' where doc_id = '${docIdsPrev}' and readinglist_id ='${readingListId}'`;
        if (docIdsPrev.length === 0) {
            dbQuery = `update "iMS".phm_ims_ReadingListDocIDs set doc_id='${docIDsNow}' where readinglist_id ='${readingListId}'`;
        }
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    deleteDocIdFromReadingList = async (docID, readingListId) => {
        let dbQuery = `delete from "iMS".phm_ims_ReadingListDocIDs where doc_id = '${docID}' and readinglist_id ='${readingListId}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    selectDocIdFromReadingList = async (readingListId) => {
        let dbQuery = `select count(*) from "iMS".phm_ims_ReadingListDocIDs where readinglist_id ='${readingListId}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    selectDocFromReadingList = async (readingListId) => {
        let dbQuery = `select doc_id from "iMS".phm_ims_ReadingListDocIDs where readinglist_id ='${readingListId}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    createBookmark = async (
        username,
        bookmarkName,
        searchProprety,
        currentDate
    ) => {
        bookmarkName = bookmarkName.replace(/'/g, "''");
        searchProprety = searchProprety.replace(/'/g, "''");
        let dbQuery = `insert into "iMS".phm_ims_bookmark (username, bookmark_name, search_property, added_on) values ('${username}', '${bookmarkName}', '${searchProprety}','${currentDate}')`;
        await this.services.postServiceForDb(dbQuery);
    };
    getBookmarkById = async (username) => {
        let dbQuery = `select * from "iMS".phm_ims_bookmark where username = '${username}'`;
        var bookmark = await this.services.getService(null, dbQuery);
        return bookmark;
    };
    deleteBookmark = async (username, bookmarkName) => {
        bookmarkName = bookmarkName.replace(/'/g, "''");
        bookmarkName = encodeURIComponent(bookmarkName);
        let dbQuery = `delete from "iMS".phm_ims_bookmark where username = '${username}' and bookmark_name = '${bookmarkName}'`;
        var bookmark = await this.services.getService(null, dbQuery);
        return bookmark;
    };
    updateBookmark = async (bookmarkName, bookmarkId) => {
        bookmarkName = bookmarkName.replace(/'/g, "''");
        bookmarkName = encodeURIComponent(bookmarkName);
        let dbQuery = `update "iMS".phm_ims_bookmark set bookmark_name='${bookmarkName}' where bookmark_id = ${bookmarkId}`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    createCollection = async (
        loginid,
        collectionid,
        collectionname,
        sessionid
    ) => {
        collectionname = collectionname.replace(/'/g, "''");
        let dbQuery = `call "iMS".phm_ims_addupdatecollection('${loginid}','${collectionid}','${collectionname}','${sessionid}')`;
        await this.services.postServiceForDb(dbQuery);
    };
    getCollectionById = async (loginid) => {
        let dbQuery = `select * from "iMS".phm_ims_collections where login_id = '${loginid}' order by added_on desc`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    getAllCollectionById = async (loginid, from, size, searchKeyword) => {
        let likeQuery = ``;
        if (searchKeyword) {
            searchKeyword = searchKeyword.replace(/'/g, "''");
            likeQuery = `AND LOWER(pic.collection_name) like '%${searchKeyword.toLowerCase()}%'`;
        }
        let dbQuery = `select pic.collection_id ,pic.collection_name ,pic.added_on,count(pict.session_id) as threads_count  from "iMS".phm_ims_collections pic LEFT JOIN "iMS".phm_ims_collection_threads pict
        ON pic.collection_id  = pict.collection_id where pic.login_id = '${loginid}' ${likeQuery} group by pic.collection_id ,pic.collection_name ,pic.added_on 
         order by pic.added_on desc LIMIT ${size} OFFSET ${from}`;
        var collections = await this.services.postServiceForDb(dbQuery);
        if (collections && collections.data && collections.data.rows && collections.data.rows.length > 0) {
            return collections.data.rows;
        }
        else {
            return [];
        }
        //var collections = await this.services.getService(null, dbQuery);
    };
    getAnswerCollectionBySessionId = async (session_ids) => {
        let sessionIds = session_ids.join("','")
        let dbQuery = `select  pic.collection_id ,pic.collection_name ,pic.added_on,pict.session_id  from "iMS".phm_ims_collection_threads pict LEFT JOIN "iMS".phm_ims_collections pic
        ON pict.collection_id=pic.collection_id  where session_id in ('${sessionIds}') order by pic.added_on desc`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };

    getCollectionCountById = async (loginid, searchKeyword) => {
        let likeQuery = ``;
        if (searchKeyword) {
            searchKeyword = searchKeyword.replace(/'/g, "''");
            likeQuery = `AND LOWER(pic.collection_name) like '%${searchKeyword.toLowerCase()}%'`;
        }
        let dbQuery = `select count(*) as collections_count  from "iMS".phm_ims_collections pic where pic.login_id = '${loginid}' ${likeQuery}`;
        var collections = await this.services.postServiceForDb(dbQuery);
        if (collections && collections.data && collections.data.rows && collections.data.rows.length > 0) {
            return collections.data.rows;
        }
        else {
            return [];
        }
        // var collections = await this.services.getService(null, dbQuery);
        // return  ;
    };
    deleteAllThreadFromCollection = async (threadId, collection_ids) => {
        let collectionids = collection_ids.join("','")
        let dbQuery = `delete from "iMS".phm_ims_collection_threads where session_id = '${threadId}' and collection_id in ('${collectionids}');`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    deleteCollection = async (loginid, collectionid) => {

        let dbQuery = `delete from "iMS".phm_ims_collection_threads where collection_id = '${collectionid}'; delete from "iMS".phm_ims_collections where login_id = '${loginid}' and collection_id = '${collectionid}'`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    updateCollection = async (collectionname, collectionid) => {
        collectionname = collectionname.replace(/'/g, "''");
        collectionname = encodeURIComponent(collectionname);
        let dbQuery = `update "iMS".phm_ims_collections set collection_name='${collectionname}' where collection_id = '${collectionid}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    deleteAllThreadByUserId = async (loginid) => {
        let dbQuery = `delete from "iMS".phm_ims_collection_threads where collection_id in ( SELECT x.collection_id FROM "iMS".phm_ims_collections x where x.login_id='${loginid}')`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    deleteThreadById = async (session_id) => {
        let dbQuery = `delete from "iMS".phm_ims_collection_threads where session_id='${session_id}'`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    getThreadByCollectionId = async (collectionId) => {
        let dbQuery = `select x.id, x.session_id from "iMS".phm_ims_collection_threads x where x.collection_id = '${collectionId}' order by x.added_on desc`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    getThreadCountByCollectionId = async (collectionId) => {
        let dbQuery = `select count(*) as threads_count  from "iMS".phm_ims_collection_threadsx where x.collection_id = '${collectionId}'`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    getCollectionByCollectionId = async (collectionId) => {
        let dbQuery = `select * from "iMS".phm_ims_collections where collection_id = '${collectionId}'`;
        var collections = await this.services.getService(null, dbQuery);
        return collections;
    };
    updateActiveStateinDb = async (userid, status) => {
        let dbQuery = `call "iMS".phm_ims_updatelastlogin('${userid}')`
        await this.services.putService(dbQuery)
    }
    getReadingListsWithDocIds = async (username) => {
        let dbQuery = `select rl.readinglist_id,  rldid.doc_id from "iMS".phm_ims_readinglist rl 
        inner join "iMS".phm_ims_readinglistdocids rldid on rl.readinglist_id=rldid.readinglist_id where username = '${username}'`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }

    getNotebookLists = async (loginId) => {
        let dbQuery = `SELECT login_id, notebooklist_id, notebooklist_name, added_on, isdeleted, deleted_on
        FROM "iMS".phm_ims_notebooklist
        where login_id = '${loginId}' order by added_on desc`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    getNotebookItemsList = async (notebooklistId) => {
        let dbQuery = `SELECT id, notebooklist_id, original_text, summarized_text, reference, notes, added_on, order_index, table_summarized_html, command_type,download_url, doc_title, command_text
        FROM "iMS".phm_ims_notebooklistitems
        where notebooklist_id = '${notebooklistId}' order by added_on`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    getUserCommandLists = async (loginId) => {
        let dbQuery = `SELECT id, login_id, command_name, command_description, command_text, added_on
        FROM "iMS".phm_ims_usercommand where login_id = '${loginId}' order by added_on desc`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    getTemplateCommandLists = async () => {
        let dbQuery = `SELECT  id, command_name, command_description, command_text, order_index, added_on FROM "iMS".phm_ims_templatecommand order by order_index`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    getUserPromptLists = async (loginId) => {
        let dbQuery = `SELECT x.* FROM "iMS".phm_ims_user_prompt_templates x where x.login_id = '${loginId}' order by x.added_on desc`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    getTemplatePromptLists = async () => {
        let dbQuery = `SELECT x.* FROM "iMS".phm_ims_system_prompt_templates x ORDER BY x.order_index`
        let res = await this.services.getService(null, dbQuery);
        return res;
    }
    preparePDFDocument = async (htmldocument) => {
        let css = UtilityServices.getPdfCSS();
        let doc = `<!DOCTYPE html><html><head><meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1.0'>
        <style>
        ${css}
    </style>
        </head><body><div class='App'>${htmldocument.replace(
            /\\"/g,
            "'"
        )} </div></body></html>`;
        let payload = {
            htmldocument: doc,
            downloadFile: "PDF",
        };
        return await axios.post(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}getfiles/ftype/`,
            payload,
            {
                responseType: "blob",
            }
        );
    };
    prepareExcelDocument = async (
        csvData,
        columns,
        worksheetName = "iMS_Results_Excel",
        tables = [],
    ) => {
        let payload = {
            csvData: csvData,
            columns: columns,
            downloadFile: "Excel",
            worksheetName: worksheetName,
            tables: tables,
        };
        return await axios.post(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}getfiles/ftype/`,
            payload,
            {
                responseType: "blob",
            }
        );
    };
    prepareMultipleWorksheetsExcelDocument = async (excelDatas) => {
        let payload = {
            excelDatas: excelDatas,
            downloadFile: "MultipleWorksheetsExcel",
        };
        return await axios.post(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}getfiles/ftype/`,
            payload,
            {
                responseType: "blob",
            }
        );
    };
    downloadZipFile = async (urlData) => {
        return await axios.post(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}downloadzip/zip`,
            urlData,
            {
                responseType: "blob",
            }
        );
    };
    prepareWordDocument = async (htmldocument, downloadFile) => {
        let css = UtilityServices.getNotebookPdfCSS();
        let doc = `<!DOCTYPE html><html><head><meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1.0'>
        <style>
        ${css}
    </style>
        </head><body><div class='App'>${htmldocument.replace(
            /\\"/g,
            "'"
        )} </div></body></html>`;
        let payload = {
            htmldocument: doc,
            downloadFile: downloadFile
        };
        return await axios.post(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}getfiles/ftype/`,
            payload,
            {
                responseType: "blob",
            }
        );
    };
    findsearchtype = async (searchterm) => {
        let dbQuery = `select * from "iMS".phm_ims_mdm_findsearchtype('${searchterm}')`;
        // console.log(dbQuery)
        let res = await this.services.getService(null, dbQuery);
        // console.log(res)
        return res;
    };
    labelpopulate = async (searchterm, searchtermlabel) => {
        let dbQuery = `select * from "iMS".phm_ims_mdm_labelpopulate('${searchterm}','${searchtermlabel}')`;
        // console.log(dbQuery)
        let res = await this.services.getService(null, dbQuery);
        // console.log(res)
        return res;
    };
    findclinicalandcrsandcompound = async (searchterm, whatsearch) => {
        let dbQuery = `select * from "iMS".phm_ims_mdm_findclinicalandcrsandcompound('${searchterm}','${whatsearch}')`;
        let res = await this.services.getService(null, dbQuery);
        // console.log(res)
        return res;
    };
    clinicalStudiesEvidencePanelData = async (selectedProduct) => {
        let dbQuery = `select * from "iMS".phm_ims_clinicalstudiesknowledge where product = '${selectedProduct}'`;
        let res = await this.services.getService(null, dbQuery);
        // console.log(res)
        return res;
    };
    getDocidMapping = async (docid) => {
        let dbQuery = `select * from  "iMS".phm_ims_docid_mappings where docid='${docid}'`;
        let res = await this.services.getService(null, dbQuery);
        return res;
    };
    initPreviewCall = async (
    ) => {
        let url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview`
        let payload = {
            strID: 'cc88e690f466a1fecdedcc51f5dbca32',
            strPageNum: 1,
            strKeyword: ''
        };
        await axios.post(
            url,
            payload
            , {
                responseType: "blob",
            }
        ).then((response) => {

        }).catch((error) => {
            console.log(error);
        });
    };
    createGenAIAnswerFeedback = async (
        login_id, session_id, event_id, question, display_answer, question_type, thread_id,
        is_multi_files, feedback, is_get_any_ans, is_success, filters
    ) => {
        question = question.replace(/'/g, "''");
        display_answer = display_answer.replace(/'/g, "''");
        feedback = feedback.replace(/'/g, "''");
        filters = filters.replace(/'/g, "''");
        let dbQuery = `call "iMS".phm_ims_addgenaianswerfeedbacks(
        '${login_id}'
        ,'${session_id}'
        ,'${event_id}'
        ,'${question}'
        ,'${display_answer}'
        ,'${question_type}'
        ,'${thread_id}'
        ,${is_multi_files}
        ,'${feedback}'
        ,${is_get_any_ans}
        ,${is_success}
        ,'${filters}')`;
        await this.services.postServiceForDb(dbQuery);
    };
    UpdateDisclaimerStatus = async (wwid, columnName, columnValue) => {
        let dbQuery = `UPDATE "iMS".phm_ims_userdetails
        SET ${columnName}='${columnValue}'
        WHERE wwid='${wwid}'`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    }
    getAllDocDetails = async () => {
        let dbQuery = 'SELECT docid, doctitle, doctext, doctextproperties, folderid, datemodified, voice, rate,language FROM "iMS".phm_ims_ttsaudio'
        let doc = await this.services.getService(null, dbQuery);
        return doc;
    }
    getAllFolderDetails = async () => {
        let dbQuery = 'SELECT folderid, foldername, parentfolderid, tag FROM "iMS".phm_ims_ttsaudiofolders'
        let folder = await this.services.getService(null, dbQuery);
        return folder;
    }
    getlastFolderId = async () => {
        let dbQuery = 'SELECT MAX(folderid) FROM "iMS".phm_ims_ttsaudiofolders'
        let folderid = await this.services.getService(null, dbQuery);
        return folderid[0].max;
    }
    getDistLexiconFile = async () => {
        let dbQuery = 'SELECT DISTINCT r.lexiconid,l.name from \"iMS\".phm_ims_ttslexicon l INNER JOIN \"iMS\".phm_ims_ttslexiconphonemerel r ON r.lexiconid = l.id ORDER BY l.name';
        let lexiconFile = await this.services.getService(null, dbQuery);
        return lexiconFile;
    }
    getLexiconFile = async () => {
        let dbQuery = 'SELECT id, name from "iMS". phm_ims_ttslexicon';
        let lexiconFile = await this.services.getService(null, dbQuery);
        return lexiconFile;
    }
    pushFolder = async (folderName, folder_id, tagName) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_ttsaudiofolders(foldername, parentfolderid, tag)VALUES('${folderName}', ${folder_id}, '${tagName}')`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    };
    pushFile = async (currentDate, docstream, fileContent, doctextproperties, filename, folder_id, voice, rate, language) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_ttsaudio(datemodified,docstream,doctext,doctextproperties,doctitle,folderid,voice,rate,language)VALUES('${currentDate}', ${docstream}, '${fileContent}', '${doctextproperties}', '${filename}', ${folder_id} ,'${voice}', '${rate}','${language}')RETURNING docid; COMMIT`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res;
    }
    pushLexiFile = async (name) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_ttslexicon (name) VALUES('${name}')`;
        var res = await this.services.getService(null, dbQuery)
        return res;
    }
    updateFolderName = async (folder_id, foldername) => {
        let dbQuery = `update "iMS".phm_ims_ttsaudiofolders set foldername='${foldername}' where folderid = ${folder_id}`;
        var res = await this.services.putService(dbQuery);
        return res
    }
    updateDocumentName = async (doc_id, docname) => {
        let dbQuery = `update "iMS".phm_ims_ttsaudio set doctitle='${docname}' where docid = ${doc_id}`;
        var res = await this.services.putService(dbQuery)
        return res
    }
    updateFolderTag = async (tag, id) => {
        let dbQuery = `update "iMS".phm_ims_ttsaudiofolders set tag= '${tag}' where folderid = ${id}`;
        var res = await this.services.putService(dbQuery);
        return res
    }
    updateDocumentTag = async (tag, id) => {
        let dbQuery = `update "iMS".phm_ims_ttsaudio set tag = '${tag}' where docid =${id}`;
        var res = await this.services.putService(dbQuery);
        return res
    }
    updateDocumentVoiceRate = async (doc_id, voice, rate, text, lang) => {
        let dbQuery = `UPDATE "iMS".phm_ims_ttsaudio SET
        voice ='${voice}',
        rate ='${rate}',
        doctext = '${text}',
        language = '${lang}'
         where docid = ${doc_id}`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res
    }
    updateLexicon = async (id, grapheme, phoneme) => {
        let dbQuery = `DO $$ DECLARE phoneme_id INT; BEGIN INSERT INTO "iMS".phm_ims_ttsphoneme (grapheme,phoneme) Values(${grapheme},${phoneme}) RETURNING id INTO phoneme_id; INSERT INTO "iMS".phm_ims_ttslexiconphonemerel VALUES (${id},${id}); END $$;`;
        var res = await this.services.getService(dbQuery);
        return res
    }
    deleteFolder = async (folderId, folderName) => {
        let dbQuery = `DELETE FROM "iMS".phm_ims_ttsaudiofolders WHERE folderid= ${folderId} and foldername = '${folderName}'`;
        var res = await this.services.getService(null, dbQuery);
        return res
    };
    deleteFiles = async (docId, docTitle) => {
        let dbQuery = `delete from "iMS".phm_ims_ttsaudio where docid= ${docId}`;
        var res = await this.services.getService(null, dbQuery);
        return res
    };
    getLatestAnswerDisclaimer = async () => {
        let dbQuery = `SELECT  piam.id,  piam.disclaimer,  piam.disclaimer_version
                        FROM "iMS".phm_ims_answerdisclaimermaster  piam
                        WHERE piam.disclaimer_version = (SELECT MAX(disclaimer_version) FROM "iMS".phm_ims_answerdisclaimermaster)`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getAnswerDisclaimerUserAuthenticate = async (login_id) => {
        let dbQuery = `SELECT  piam.id,  piam.disclaimer,  piam.disclaimer_version,  piaua.is_authenticate ,piaua.login_id 
                        FROM "iMS".phm_ims_answerdisclaimermaster  piam
                        LEFT JOIN "iMS".phm_ims_answerdisclaimer_user_authenticate piaua ON piam.id = piaua.answerdisclaimermaster_id 
                        WHERE disclaimer_version = (SELECT MAX(disclaimer_version) FROM "iMS".phm_ims_answerdisclaimermaster)
                        and piaua.login_id =${login_id}`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getSystemPromptTemplatesForTextArea = async (_limit = 3) => {
        let dbQuery = `SELECT x.* FROM "iMS".phm_ims_system_prompt_templates x ORDER BY x.order_index  LIMIT ${_limit}`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    getSystemPromptTemplates = async () => {
        let dbQuery = `SELECT x.* FROM "iMS".phm_ims_system_prompt_templates x ORDER BY x.order_index`;
        var res = await this.services.getService(null, dbQuery);
        return res;
    };
    insertAnswerDisclaimerUserAuthenticate = async (login_id, disclaimer_id) => {
        let dbQuery = `INSERT INTO "iMS".phm_ims_answerdisclaimer_user_authenticate
                        (login_id, is_authenticate, answerdisclaimermaster_id)
                        VALUES(${login_id}, true, ${disclaimer_id})  ON CONFLICT (login_id,answerdisclaimermaster_id)
                        DO UPDATE set is_authenticate=true;`;
        var res = await this.services.postServiceForDb(dbQuery);
        return res
    }

}

