import React, { Component } from 'react';
import {
  BrowserRouter as Router, Route,
  Switch, Redirect
} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
// import { v4 as uuidv4 } from 'uuid';
import Disclaimer from './content/Pages/Disclaimer';
import Registration from './content/Pages/Registration';
import Test from './content/Pages/Test';
import ContactUs from './content/FooterLinks/Contact-Us';
import GetHelp from './content/FooterLinks/GetHelp';
import PrivacyPolicy from './content/FooterLinks/PrivacyPolicy';
import RecommendedHome from './content/Pages/RecommendedHome';
import FieldedSearch from './content/Pages/FieldedSearch';
import Results from './content/Pages/Results';
import AdminDashboard from './content/Pages/AdminDashBoard';
import ApiServices from './utils/apiServices';
import RegisterRole from './content/Pages/Register-role';
import C19ByProducts from './components/ContentShortcuts/Covid19ContentByProducts';
import RawValues from './content/Pages/RawValues';
import DocURL from './content/Pages/DocURL';
import trackingService from './services/trackingService';
import Reports from './content/Pages/Reports';
import { imsLoader } from './assets';
import configDetails from './config/config'
import WNFYPage from './components/WNFY/WNFYPage';
import WOAVPage from './components/WOAV/WOAVPage';
import ViewSelectedResults from './content/Pages/ViewSelectedResults';
import ExcelView from './content/Pages/ExcelView';
import Download from './content/Pages/Download';
import ManageAlerts from './content/Pages/ManageAlerts';
import HtmlPreview from './content/Pages/HtmlPreview';
import ReadingList from './content/Pages/ReadingList';
import DownloadDocs from './content/Pages/DownloadDocs';
import DownloadFinish from './content/Pages/DownloadFinish';
import PubMedResults from './content/Pages/PubMedResults';
import ReferencesUsedMIAs from './content/Pages/ReferencesUsedMIAs';
import ViewSelectedResultsMIAsReferences from './content/Pages/ViewSelectedResultsMIAsReferences';
import MIAsReferencesHtmlView from './content/Pages/MIAsREferencesHtmlView';
import DocURLPubMed from './content/Pages/DocURLPubMed';
import KnowledgePanelData from './components/ClinicalStudiesKnowledgePanel/KnowledgePanelData';
import GetContentDocURL from './content/Pages/GetContentDocURL';
import HandleResults from './content/Pages/HandleResults';
import HandleDocURL from './content/Pages/HandleDocURL';
import Cookies from 'universal-cookie';
import { UserData } from './auth/Authorization';
import NlpResults from './content/Pages/NlpResults';
import TestResults from './content/Pages/TestResults';
import { Modal } from 'react-bootstrap';
import Button from "./components/Button";
import NotebookView from './content/Pages/NotebookView';
import Authoring from './content/Pages/Authoring';
import JanssenScience from './content/Pages/JanssenScience';
import ReactDocViewer from './content/Pages/ReactDocViewer';
import GenAIAnswering from './content/Pages/GenAIAnswering';
import GenAILibrary from './content/Pages/GenAILibrary';
import GenAISearch from './content/Pages/GenAISearch';
import GenAICollection from './content/Pages/GenAICollection';
import { GenAIAnsweringContext } from './components/GenAIAnswering2.0/GenAIAnsweringContextProvider';
import GenAIAnsweringPreview from './content/Pages/GenAIAnsweringPreview';
import TTSManageFiles from './content/Pages/TTSManageFiles';
import TSSMyTaskIndex from './content/Pages/TSSMyTaskIndex';
import GenAIAuthoring from './content/Pages/GenAIAuthoring';

import AudioContentPage from './content/TSS/AudioContentPage';
import AllLexicon from './components/TTS/AllLexicon';

const _cookies = new Cookies();
class Routes extends Component {
  userid;
  userExists;
  static contextType = GenAIAnsweringContext;
  constructor(props) {
    super(props);
    //var timeout=parseInt(process.env.REACT_APP_SESION_TIMEOUT)
    var timeout = parseInt(configDetails.SESSION_TIMEOUT)
    this.state = {
      username: '',
      userExists: false,
      welcomeUser: false,
      loaded: false,
      datatracking: '',
      timeout: 1000 * timeout,
      isTimeOut: false
    }
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }
  handleWindowClose = (status) => {
    let mail = localStorage.getItem('username').toLowerCase();
    let service = new ApiServices();
    service.updateActiveStateinDb(mail, status);
  }
  _onIdle(e) {
    //var guid = uuidv4();
    const ts = new trackingService();
    //let username = localStorage.getItem('username');
    ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Abandoned Search', 'Session Times Out', window.location.href, this.state.searchKeyword, this.state.appliedFilters);
    this.handleWindowClose(false);
    //_cookies.set("sid", JSON.stringify(guid), { path: '/' });
    //localStorage.setItem('sid',JSON.stringify(guid));
    // For User Entry Tracking    
    //ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'User Entry Point','Direct Entry', 'Direct')
    this.handleWindowClose(true);
    //this.idleTimer.reset();
    _cookies.remove("sid");
    _cookies.remove("isSidExpired");
    this.setState({ isTimeOut: true });
  }

  handleClickOnOK = async () => {
    this.idleTimer.reset();
    window.location.reload();
  }
  userValidation = async (emailId) => {
    const services = new ApiServices();
    //var mail = emailId.toLowerCase();
    //let wwid = JSON.parse(localStorage.getItem('wwid'));
    let wwid = UserData.sub;
    var response = await services.getUserDetails(wwid);
    if (response) {
      //console.log(response);
      localStorage.setItem('userExists', JSON.stringify(true));
      localStorage.setItem('region', response.region);
      localStorage.setItem('function', JSON.stringify(response.function));
      localStorage.setItem('userproducts', response.products);
      localStorage.setItem('doctypepref', response.docs);
      localStorage.setItem('russianuser', JSON.stringify(response.russianuser));
      localStorage.setItem('contentpreference', JSON.stringify(response.contentprefernce));
      localStorage.setItem('showPreviewAuthoringDisclaimer', JSON.stringify(response.isshowpreviewauthoringdisclaimer));
      localStorage.setItem('showGlobalAuthoringDisclaimer', JSON.stringify(response.isshowglobalauthoringdisclaimer));
      localStorage.setItem('showGenAIAnsweringDisclaimer', JSON.stringify(response.isshowgenaiansweringdisclaimer));
      if (response.accessgroup) {
        localStorage.setItem('accessgroup', JSON.stringify(response.accessgroup));
      } else {
        localStorage.setItem('accessgroup', JSON.stringify(''));
      }
      this.setState({
        userExists: true,
        datatracking: response.datatracking,
        loaded: true
      }, async () => {
        /*Fetch the data from JJEDs Api and set it to local storage existing user*/
        var jjedsres = await services.getJJEDSdetails(UserData.sub);
        if (jjedsres) {
          // if (this.state.datatracking === "No") {
          //   localStorage.setItem('wwid', JSON.stringify(0));
          // }
          // else {
          //   localStorage.setItem('wwid', JSON.stringify(jjedsres.uid));
          // }
          localStorage.setItem('datatracking', JSON.stringify(this.state.datatracking));
          localStorage.setItem('jnjDepartment', jjedsres.jnjdepartmentdescription ? JSON.stringify(jjedsres.jnjdepartmentdescription) : JSON.stringify('NA'));
          if (jjedsres && jjedsres.jnjdisplayname) {
            localStorage.setItem('userdisplayName', JSON.stringify(jjedsres.jnjdisplayname));
          } else if (jjedsres && jjedsres.displayname) {
            localStorage.setItem('userdisplayName', JSON.stringify(jjedsres.displayname));
          }
          else {
            localStorage.setItem('userdisplayName', "");
          }

          localStorage.setItem('legalEntityCategory', JSON.stringify(jjedsres.legalentitycategory));
          localStorage.setItem('companyname', JSON.stringify(jjedsres.companyName));
          // localStorage.setItem('jnjDepartment',JSON.stringify('Test Dept'));
          // localStorage.setItem('userdisplayName',JSON.stringify('Test User'));
          // localStorage.setItem('legalEntityCategory',JSON.stringify("007543"));
        }
      })
    }
    else {
      /*Fetch the data from JJEDs Api and set it to local storage for new user*/
      let jjeds = await services.getJJEDSdetails(UserData.sub);
      if (jjeds) {
        //console.log('Sagar1 : ' + jjeds);
        localStorage.setItem('wwid', JSON.stringify(jjeds.uid));
        localStorage.setItem('jnjDepartment', jjeds.jnjdepartmentdescription ? JSON.stringify(jjeds.jnjdepartmentdescription) : JSON.stringify('NA'));
        if (jjeds && jjeds.jnjdisplayname) {
          localStorage.setItem('userdisplayName', JSON.stringify(jjeds.jnjdisplayname));
        } else if (jjeds && jjeds.displayname) {
          localStorage.setItem('userdisplayName', JSON.stringify(jjeds.displayname));
        }
        else {
          localStorage.setItem('userdisplayName', "");
        }
        localStorage.setItem('legalEntityCategory', JSON.stringify(jjeds.legalentitycategory));
        localStorage.setItem('companyname', JSON.stringify(jjeds.companyName));
        // localStorage.setItem('wwid',JSON.stringify(80001815))
        // localStorage.setItem('jnjDepartment',JSON.stringify('Test Dept'));
        // localStorage.setItem('userdisplayName',JSON.stringify('Test User'));
        // localStorage.setItem('legalEntityCategory',JSON.stringify("007543"));
        this.setState({
          loaded: true
        })
      }
    }
  }
  async componentDidMount() {
    //console.log(`WWID: `, UserData.sub)
    this.userid = JSON.parse(localStorage.getItem('username'));
    this.userExists = localStorage.getItem('userExists')
    if (this.userExists) {
      this.setState({
        userExists: this.userExists,
        loaded: true
      })
    }
    else {
      this.setState({
        username: this.userid
      }, () => {
        if ((!localStorage.getItem("contentpreference")) ||
          (!localStorage.getItem("russianuser")) ||
          (!localStorage.getItem("doctypepref")) ||
          (!localStorage.getItem("userproducts")) ||
          (!localStorage.getItem("function")) ||
          (!localStorage.getItem("region"))) {
          this.userValidation(this.state.username)
        } else {
          this.setState({
            loaded: true
          })
        }
      })
    }
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    let data = sessionStorage.getItem("GenAIAnsweringSessionId");
    if (window.location.pathname.startsWith('/genAISearch') && data) {
      window.location.href = `${window.location.origin}/genAIAnswering`;
    }
    if (data) {
      await this.context.deleteIndexOnLoad(data);
      sessionStorage.removeItem("GenAIAnsweringSessionId");
    }
  }
  handleBeforeUnload = async () => {
    this.context.deleteIndexBySessionId();
  }
  render() {
    return (
      /* <App>   */
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          debounce={250}
          timeout={this.state.timeout}
          // crossTab={{
          //   emitOnAllTabs: true
          // }} 
          key="Route"
        />
        {this.state.loaded ?
          <Router>
            <Switch>
              {!this.state.userExists ?
                <Route exact path="/" component={Disclaimer} />
                :
                <Route exact path="/" component={RecommendedHome} />
              }
              <Route exact path="/recommended" component={RecommendedHome}></Route>
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/register-role" component={RegisterRole}></Route>
              <Route exact path="/DocURL" component={DocURL}></Route>
              <Route exact path="/test" component={Test}></Route>
              <Route exact path="/Contact-Us" component={ContactUs}></Route>
              <Route exact path="/Get-Help" component={GetHelp}></Route>
              <Route exact path="/PrivacyPolicy" component={PrivacyPolicy}></Route>
              <Route
                path="/Pages/recommended.aspx"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: location.pathname.replace("Pages/recommended.aspx", 'recommended'),
                    }}
                  />
                )}
              />
              <Route exact path="/advancedsearch" component={FieldedSearch}></Route>
              <Route exact path="/results" component={Results}></Route>
              <Route exact path="/nlpresults" component={NlpResults}></Route>
              <Route exact path="/testResults" component={TestResults}></Route>
              <Route exact path="/notebookView" component={NotebookView}></Route>
              <Route exact path="/authoring" component={Authoring}></Route>
              <Route exact path="/janssenscience" component={JanssenScience}></Route>
              <Route exact path="/reactDocViewer" component={ReactDocViewer}></Route>
              <Route exact path="/genAIAnswering" component={GenAIAnswering}></Route>
              <Route exact path="/genAILibrary" component={GenAILibrary}></Route>
              <Route exact path="/genAISearch" component={GenAISearch}></Route>
              <Route exact path="/genAISearch/:id" component={GenAISearch}></Route>
              <Route exact path="/genAICollection/:id" component={GenAICollection}></Route>
              <Route exact path="/genAIAnsweringPOC" component={GenAIAnswering}></Route>
              <Route exact path="/genAILibraryPOC" component={GenAILibrary}></Route>
              <Route exact path="/genAISearchPOC" component={GenAISearch}></Route>
              <Route exact path="/genAISearchPOC/:id" component={GenAISearch}></Route>
              <Route exact path="/genAICollectionPOC/:id" component={GenAICollection}></Route>
              <Route exact path="/genAIAnsweringPreview/:id" component={GenAIAnsweringPreview}></Route>
              <Route exact path="/GenAIAuthoring" component={GenAIAuthoring}></Route>
              {/* {<Route
                path="/Pages/results.aspx"
                component={({ location })=>(
                  <Redirect
                    to={{
                      ...location,
                      pathname:location.pathname.replace("/Pages/results.aspx", '/results'),
                    }}
                  />
                )}
                />} */}
              <Route exact path="/GetContentDocURL" component={GetContentDocURL}></Route>
              {<Route
                path="/Pages/GetContent.aspx"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: location.pathname.toLowerCase()
                        .replace("/pages/getcontent.aspx", '/GetContentDocURL'),
                    }}
                  />
                )}
              />}
              <Route exact path="/HandleDocURL" component={HandleDocURL}></Route>
              {<Route
                path="/Pages/DocURL.aspx"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: location.pathname.toLowerCase()
                        .replace("/pages/docurl.aspx", '/HandleDocURL'),
                    }}
                  />
                )}
              />}
              <Route exact path="/HandleResults" component={HandleResults}></Route>
              {<Route
                path="/Pages/results.aspx"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: location.pathname.toLowerCase()
                        .replace("/pages/results.aspx", '/HandleResults'),
                    }}
                  />
                )}
              />}
              <Route exact path="/pubMedResults" component={PubMedResults}></Route>
              <Route exact path="/adminConsole" component={AdminDashboard}></Route>
              <Route exact path="/reports" component={Reports}></Route>
              <Route exact path="/covid" component={C19ByProducts}></Route>
              <Route path="/rawvalues" component={RawValues}></Route>
              <Route path="/WhatsNewForYou" component={WNFYPage}></Route>
              <Route path="/WhatOthersAreViewing" component={WOAVPage}></Route>
              <Route exact path="/viewmarked" component={ViewSelectedResults}></Route>
              <Route exact path="/Excel-View" component={ExcelView} ></Route>
              <Route exact path="/Download" component={Download}></Route>
              <Route exact path="/ManageAlerts" component={ManageAlerts}></Route>
              <Route exact path="/iMS_Emails" component={HtmlPreview}></Route>
              <Route exact path="/viewReadingList" component={ReadingList}></Route>
              <Route exact path="/docs" component={DownloadDocs}></Route>
              <Route exact path="/Finish" component={DownloadFinish}></Route>
              <Route exact path="/ReferencesUsed" component={ReferencesUsedMIAs}></Route>
              <Route exact path="/ViewSelectedReferencesUsed" component={ViewSelectedResultsMIAsReferences}></Route>
              <Route exact path="/MIAsRefDownload" component={MIAsReferencesHtmlView}></Route>
              <Route exact path="/DocURLPubMed" component={DocURLPubMed}></Route>
              <Route exact path="/CSKP" component={KnowledgePanelData}></Route>
             
              <Route path ="/TTSAudioContentPage/:id" component={AudioContentPage}></Route>
              <Route path = '/TTSHome' component={TTSManageFiles}></Route>
              <Route path = '/TTSHome/:id' component={TTSManageFiles}></Route>
              <Route path = '/TTSMyTask' component={TSSMyTaskIndex}></Route>
              <Route path = '/AllLexiconFile' component ={TSSMyTaskIndex}></Route>
              
            </Switch>
          </Router> :
          <div style={{ textAlign: "center", padding: "10%" }}>
            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
          </div>
        }
        {this.state.isTimeOut &&
          <Modal
            size="md sm xs"
            show={this.state.isTimeOut}
            // centered
            // aria-labelledby="contained-modal-title-vcenter"
            // dialogClassName="popup-transition"
            keyboard={false}
            dialogClassName="popTimeOut"
            className="z-index-popTimeOut"
            backdropClassName="z-index-popTimeOut-backdrop"
          >
            <div style={{ borderLeft: `5px solid #2EB000` }}>
              <Modal.Body>
                You are timed out. Please click on OK to start a new session.
              </Modal.Body>
              <Modal.Footer>
                <Button text="OK" customColor='#2EB000' action={
                  this.handleClickOnOK
                } />
              </Modal.Footer>
            </div>
          </Modal>
        }
      </>
      /* </App> */
    )
  }


}
export default Routes;