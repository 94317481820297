import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import {
    robotHeadChatgpt, imsLoader, chatbotHeaderIcon, crossIcon, iconUserChatbot, iconiMSAuthoring, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon, expandAllBlueIcon,
    globalExpandAllIcon, pdfIcon, rightSideArrow, BarGraph, BubbleGraph, PieGraph, LinePlot, LineScatterGraph, arrowIconBlue
} from '../../assets/index';
import { v4 as uuidv4 } from 'uuid';
import "./index.css"
import { Modal } from "react-bootstrap";
import { LocationOrigin, getCurrentDate, WordsMaxLength } from '../../utils/utilityServices';
import { processFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices';
import GenAIAnsweringDisclaimer from './GenAIAnsweringDisclaimer'
import ApiServices from "../../utils/apiServices";
import GenAIAuthoringSidebar from './GenAIAuthoringSidebar'
import ExpandContentPopUpModal from '../PopUp/ExpandContentPopUpModal';
import CommandListPopUp from "../PopUp/CommandListPopUp";
import { clone, object, uniq } from 'underscore'
import moment from "moment";
import axios from 'axios';
import GenAIAuthoringDisplayString from './GenAIAuthoringDisplayString'
import { sanitize } from 'dompurify';
import AuthoringUserSummery from "../Authoring/AuthoringUserSummery";
import Services from "../../utils/services";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";

let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';
const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';
const summarizeResult = 'SummarizeResult';
const userQuestion = 'UserQuestion';
const ShowWordCountForCommandType = ['Translate', 'CreateTable'];

class GenAIAuthoringIndex extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = createRef()
        let _showDisclaimerPopup = true;
        let strShowDisclaimerPopup = localStorage.getItem('showGlobalAuthoringDisclaimer');
        if (strShowDisclaimerPopup) {
            _showDisclaimerPopup = !JSON.parse(strShowDisclaimerPopup);
        }
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            sessionID: props.location.state ? props.location.state.sessionID : (props.match.params && props.match.params.id) ? props.match.params.id : null,
            question: props.location.state ? props.location.state.newQuestion : null,
            attachedFiles: props.location.state ? props.location.state.attachedFiles : [],
            threadID: props.location.state ? props.location.state.threadID : null,
            selectedFilters: props.location.state ? props.location.state.selectedFilters : [],
            showDisclaimerPopup: _showDisclaimerPopup,
            collapsed: false,
            commandText: props.location.state ? props.location.state.newQuestion : '',
            commandType: 'UserInstructions',
            citationText: '',
            isCitationProcessing: false,
            selectedAttachedFileguid: (props.location.state?.attachedFiles && props.location.state?.attachedFiles.length > 0) ? props.location.state.attachedFiles[0].guid : '',
            selectedPageNo: 1,
            expandCommand: false,
            savingCommandText: '',
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
            chatConversation: [],
            isProcessing: false,
            spinnerMessage: '',
            isGraph: false,
            selectedGraph: '',
            allFileContent: {
            },
            openEditor: false,
            orignalText: '',
            summarizeText: '',
            firstCall: true,
            previousOrignalText: '',
            isConfirm: false,
            isClearAllFields: true,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isChangePage: false,
        }
    }
    async componentDidMount() {
        let _selectedAttachedFileguid = (this.props.location.state?.attachedFiles && this.props.location.state.attachedFiles?.length > 0) ? this.props.location.state.attachedFiles[0].guid : '';
        if (_selectedAttachedFileguid) {
            await this.handleFullText(_selectedAttachedFileguid);
        }

    }
    handleCollapsedClick = async (isCollapsed) => {
        if (!isCollapsed) {
            setTimeout(() => {
                this.setState({ collapsed: isCollapsed })
            }, 500);
        } else {
            this.setState({ collapsed: isCollapsed })
        }
    }
    handleDisclaimerClose = async () => {
        let strShowDisclaimerPopup = localStorage.getItem('showGlobalAuthoringDisclaimer');
        if (strShowDisclaimerPopup && !JSON.parse(strShowDisclaimerPopup)) {
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.UpdateDisclaimerStatus(wwid, 'isshowglobalauthoringdisclaimer', true);
        }
        this.setState({ showDisclaimerPopup: false });
        localStorage.setItem('showGlobalAuthoringDisclaimer', JSON.stringify(true));
    }
    handleChangefiles = (_selectedAttachedFileguid) => {
        this.setState({ selectedAttachedFileguid: _selectedAttachedFileguid, orignalText: '', commandText: '', commandType: '', citationText: '' }, async () => {
            let { allFileContent } = this.state;
            if (!Object.keys(allFileContent).find(x => x === _selectedAttachedFileguid)) {
                await this.handleFullText(_selectedAttachedFileguid);
            }
        });
    };

    handleOnClickcommandTextExpand = () => {
        this.setState({ expandCommand: true })
    }
    handleCommandTextExpand = (commandText) => {
        this.setState({ commandText: commandText, expandCommand: false })
    }
    handleAddToCommandText = (commandText) => {
        this.setState({ commandText: commandText, showCommandList: false })
    }
    handleClickOnMyCommand = () => {
        this.setState({
            isUserCommand: true,
            isTemplateCommand: false,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleClickOnTemplateCommand = () => {
        this.setState({
            isUserCommand: false,
            isTemplateCommand: true,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleAddToCommand = () => {
        let { commandText } = this.state;
        if (commandText) {
            this.setState({
                showCommandList: true,
                isUserCommand: true,
                isTemplateCommand: false,
                isFromGlobalFeaturesMenu: false
            })
        }
    }
    handleCloseCommandList = () => {
        this.setState({
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
            isFromGlobalFeaturesMenuForNotebook: false
        });
    }
    handleChangeCommandType = (e) => {
        this.setState({ commandType: e.target.value });
        /*if (e.target.value === 'Graph') {
            this.setState({ isGraph: true })
        }
        else {
            this.setState({ isGraph: false, selectedGraph: "" })

        }*/
    };
    handleComment = (e) => {
        this.setState({ commandText: e.target.value })
    }
    handleSummery = (e) => {
        this.setState({ orignalText: e.target.value })
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        let { isClickOnSelectNotebook } = this.state;
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }
    handleFullText = async (selectedAttachedFileguid) => {
        this.setState({ isProcessing: true, spinnerMessage: "Please wait." })
        let { attachedFiles, allFileContent } = this.state;
        let _singleFile = attachedFiles.find(x => x.guid === selectedAttachedFileguid);
        let resp = [];
        let API_ENDPOINT = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Content?fileType=Document`;
        //API_ENDPOINT = "https://localhost:44376/api/PreviewService/Content?fileType=Document";
        const formData = new FormData();
        formData.append('form_filed_name', _singleFile)
        await axios.post(API_ENDPOINT, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((response) => {
            resp = response.data;
            allFileContent[selectedAttachedFileguid] = resp.PagesContent
            this.setState({ allFileContent: allFileContent })
        }).catch((error) => {
            console.log(error);
            this.errorAlertMessage("Something went wrong. So we couldn't get full document content.");
        }).finally(() => {
            this.setState({ isProcessing: false, spinnerMessage: "" })
        })
    }
    onSubmitClick = async (e) => {
        let { commandText, commandType, orignalText } = this.state;
        if (commandType === 'UserInstructions' && !commandText) {
            this.errorAlertMessage("User Command Required, Please enter a command.");
        } else if (commandType === 'UserInstructions' && commandText && !orignalText) {
            this.runGenAISearch()
        } else if (commandType === 'UserInstructions' && commandText && orignalText) {
            this.onSummeryClick()
        }
        else if (commandType === 'Summarize') {
            await this.onSummeryClick();
        }
        else if (commandType === 'CreateTable' || commandType === 'CreateGraph') {
            let { allFileContent, selectedAttachedFileguid } = this.state;
            if (!Object.keys(allFileContent).find(x => x === selectedAttachedFileguid)) {
                await this.handleFullText(selectedAttachedFileguid);
            }
            await this.onSummeryClick();
        }
    }
    onSummeryClick = async (e) => {
        let { orignalText, commandText, chatConversation, commandType, sessionID,
            summarizeText, isConfirm, isClearAllFields, previousOrignalText, firstCall, selectedAttachedFileguid, allFileContent
        } = this.state;
        this.setState({ savingCommandText: commandText })
        if (orignalText || (allFileContent && Object.keys(allFileContent).find(x => x === selectedAttachedFileguid))) {
            let _commandType = commandType;
            let _commandText = commandText;
            let _orignalText = orignalText;
            if (!_orignalText && _commandType === 'Summarize') {
                _orignalText = allFileContent[selectedAttachedFileguid];
            }
            let tokenLength = this.numberOfWords(_orignalText);
            if (_commandType === 'UserInstructions' && !commandText) {
                this.errorAlertMessage("User Command Required, Please enter a command.");
            }
            else if (_commandType === 'CreateTable' && tokenLength === 0) {
                this.errorAlertMessage("For Create Table Command, Please enter text in the source text box.");
            }
            else if (_commandType === 'Translate' && !commandText) {
                this.errorAlertMessage("Please enter language to the command box.");
            } else if (!isConfirm && ShowWordCountForCommandType.includes(commandType) && tokenLength > WordsMaxLength) {
                this.showConfirmMessage(`The input text is more than ${WordsMaxLength} words. Please use a shorter text by hitting cancel button and updating the text or the text can be ${_commandType} upto max limit by clicking on OK button`);
            } else if (_commandType === 'UserInstructions' && commandText && commandText.trim() && commandText.trim().split(/\n\n/g).length > 5) {
                this.errorAlertMessage("Kindly select maximum five commands to proceed.");
            } else {
                if (_commandType === 'UserInstructions') {
                    let commandTexts = commandText.trim().split(/\n\n/g);
                    this.setState({ chatConversation: chatConversation, isProcessing: true }, () => {
                        this.scrollToBottom();
                    });

                    let _orignalText = orignalText.replace(/[\r\n]/g, " ").trim();
                    if (ShowWordCountForCommandType.includes(commandType)) {
                        _orignalText = orignalText.replace(/[\r\n]/g, " ").trim().split(' ').filter((x, i) => i < WordsMaxLength).join(' ')
                    }
                    let config = {}
                    var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    if (window.location.origin === 'https://predev-ims.jnj.com') {
                        url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    }
                    //url = `https://predev-ims.jnj.com${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    config = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {
                            "orignalText": _orignalText.replace(/[\r\n]/g, " ").trim(),
                            "commandType": commandType,
                            "commandText": '',
                            "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                            "sessionID": sessionID,
                            "wwid": JSON.parse(localStorage.getItem('wwid')),
                            "previousSummarizeText": '',
                            "searchID": "",
                            "isClearAllFields": isClearAllFields,
                            "isNewOrignalText": (previousOrignalText !== orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart()),
                            "multiCommands": commandTexts
                        }
                    }
                    // this.scrollToBottom();
                    await axios(config).then((response) => {
                        let _summarizeText = "", requestTokenCount = 0, responseTokenCount = 0;
                        if (response.data.answersList && response.data.answersList.length > 0) {
                            if (response.data.requestTokenCount)
                                requestTokenCount = response.data.requestTokenCount;
                            if (response.data.responseTokenCount)
                                responseTokenCount = response.data.responseTokenCount;

                            this.setState({ lastCommandType: _commandType }, () => {

                                commandTexts.map((citem, cindex) => {
                                    let _message = "";
                                    _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                                    _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                    chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                                    _summarizeText = response.data.answersList[cindex] ? response.data.answersList[cindex].trim() : ""
                                    _summarizeText = _summarizeText.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                    let finalSummery = JSON.parse(JSON.stringify(_summarizeText))
                                    chatConversation.push({ from: summarizeResult, message: finalSummery, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: true })
                                })

                            })
                            // this.indexData(_orignalText.replace(/[\r\n]/g, " ").trim(), "", commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, commandTexts, response.data.answersList);
                            //this.indexData(sourceItem, _orignalText.replace(/[\r\n]/g, " ").trim(), "", commandText, commandType, "", sessionID, requestTokenCount, responseTokenCount, commandTexts, response.data.answersList);
                        } else {
                            commandTexts.map((citem, cindex) => {
                                let _message = "";
                                _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                                _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                                chatConversation.push({ from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: false })
                            })

                        }
                        this.setState({
                            chatConversation: chatConversation,
                            summarizeText: _summarizeText
                        });
                    }).catch((error) => {
                        commandTexts.map((citem, cindex) => {
                            let _message = "";
                            _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                            _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                            chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                            chatConversation.push({ from: summarizeResult, message: `Service is unavailable at this time, Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: false })
                        })
                        this.setState({ chatConversation: chatConversation });
                        console.log(error)
                    }).finally(() => {
                        this.setState({ isProcessing: false, isConfirm: false, isClearAllFields: false, previousOrignalText: orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart(), firstCall: false }, () => {
                            this.scrollToBottom();
                        });
                    });
                }
                else{
                let _message = "";
                _message = commandText ? `${commandText}\n${_orignalText}` : `${_orignalText}`;
                _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true, isFromOriginalText: true })
                this.setState({ chatConversation: chatConversation, isProcessing: true }, () => {
                    this.scrollToBottom();
                });

                _orignalText = _orignalText.replace(/[\r\n]/g, " ").trim();
                let commandTexts = (_commandType === 'UserInstructions') ? commandText.trim().split(/\n\n/g) : [];

                if (ShowWordCountForCommandType.includes(commandType)) {
                    _orignalText = _orignalText.replace(/[\r\n]/g, " ").trim().split(' ').filter((x, i) => i < WordsMaxLength).join(' ')
                }
                let config = {}

                var url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                if (window.location.origin === 'https://predev-ims.jnj.com') {
                    url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                }
                config = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "orignalText": _orignalText.replace(/[\r\n]/g, " ").trim(),
                        "commandType": commandType,
                        "commandText": commandText,
                        "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                        "sessionID": sessionID,
                        "wwid": JSON.parse(localStorage.getItem('wwid')),
                        "previousSummarizeText": summarizeText.replace(/[\r\n]/g, " ").trim(),
                        "searchID": "",
                        "isClearAllFields": isClearAllFields,
                        "isNewOrignalText": (previousOrignalText !== _orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart()),
                        "multiCommands": commandTexts,
                    }
                }

                // this.scrollToBottom();
                await axios(config).then((response) => {
                    let _summarizeText = "", requestTokenCount = 0, responseTokenCount = 0;
                    if (_commandType === "CreateTable") {
                        if (response.data.summarizeTable && response.data.summarizeTable.length > 0) {
                            this.setState({ lastCommandType: _commandType }, () => {
                                let summarizeTables = response.data.summarizeTable;
                                if (response.data.requestTokenCount)
                                    requestTokenCount = response.data.requestTokenCount;
                                if (response.data.responseTokenCount)
                                    responseTokenCount = response.data.responseTokenCount;

                                if (summarizeTables.length === 1) {
                                    _summarizeText = summarizeTables[0];
                                    chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: summarizeTables[0], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: true });
                                    //this.indexData(_orignalText.replace(/[\r\n]/g, " ").trim(), _summarizeText, commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, [], []);
                                }
                            })
                        } else {
                            chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: false })
                        }
                    } else if (_commandType === 'Summarize') {
                        if (response.data.summarizeText) {
                            _summarizeText = response.data.summarizeText ? response.data.summarizeText.trim() : ""
                            if (response.data.requestTokenCount)
                                requestTokenCount = response.data.requestTokenCount;
                            if (response.data.responseTokenCount)
                                responseTokenCount = response.data.responseTokenCount;

                            this.setState({ lastCommandType: _commandType }, () => {
                                _summarizeText = _summarizeText.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: _summarizeText, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: true, isFromOriginalText: true })
                            })
                            // this.indexData(_orignalText.replace(/[\r\n]/g, " ").trim(), _summarizeText, commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, [], []);
                        } else {
                            chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: false, isFromOriginalText: true })
                        }
                    }
                    else {
                        if (response.data.answersList.length > 0) {
                            for (let i = 0; i < response.data.answersList.length; i++) {
                                _summarizeText = response.data.answersList[i].trim();
                            }
                            if (response.data.requestTokenCount)
                                requestTokenCount = response.data.requestTokenCount;
                            if (response.data.responseTokenCount)
                                responseTokenCount = response.data.responseTokenCount;

                            this.setState({ lastCommandType: _commandType }, () => {
                                _summarizeText = _summarizeText.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: _summarizeText, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: true, isFromOriginalText: true })
                            })
                            // this.indexData(_orignalText.replace(/[\r\n]/g, " ").trim(), _summarizeText, commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, [], []);
                        } else {
                            chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: false, isFromOriginalText: true })
                        }
                    }
                    this.setState({
                        chatConversation: chatConversation,
                        summarizeText: (_commandType === "Summarize" || _commandType === "UserInstructions" || _commandType === "Translate" || _commandType === "QnA") ? (response.data.summarizeText ? response.data.summarizeText.trim() : "") : _summarizeText
                    });
                }).catch((error) => {
                    chatConversation.push({ _commandType: _commandType, from: summarizeResult, message: `Service is unavailable at this time, Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: _commandText, orignalText: _orignalText, IsSucceed: false, isFromOriginalText: true })
                    this.setState({ chatConversation: chatConversation });
                    console.log(error)
                }).finally(() => {
                    this.setState({ isProcessing: false, isConfirm: false, isClearAllFields: false, previousOrignalText: _orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart(), firstCall: false }, () => {
                        this.scrollToBottom();
                    });
                });
            }

            }
        }
    }
    numberOfWords = (str) => {
        if (str) {
            const words = str.match(/\S+/g);
            if (words && words.length !== 0) {
                return words.length;
            }
            else {
                return 0;
            }
        } else {
            return 0;
        }

    }

    handleClickOnCitationNumber = (document_guid, pageNumbers) => {
        let { isChangePage } = this.state;
        this.setState({
            selectedAttachedFileguid: document_guid,
            selectedPageNo: pageNumbers.length > 0 ? pageNumbers[0] : 1,
            isChangePage: !isChangePage
        })
    }
    processChatHistories = (index) => {
        let { attachedFiles, chatConversation, threadID, sessionID } = this.state;
        let _chatHistories = {};
        let _chatConversation = Object.assign([], chatConversation);
        _chatConversation = _chatConversation.filter((x, i) => i < index).filter(x => x.threadID === threadID);
        if (chatConversation.length !== 0 && _chatConversation.length === 0 && index !== 0) {
            _chatConversation = Object.assign([], chatConversation);
            _chatConversation = _chatConversation.filter(x => x.threadID === threadID);
        }
        try {
            if (attachedFiles.length > 0) {
                attachedFiles.map((fileItem, fileIndex) => {
                    _chatHistories[fileItem.guid] = [];
                    let responseFilters = _chatConversation.filter(x => x.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid) > -1);
                    responseFilters.map((resItem, resIndex) => {
                        let indexOfSource = resItem.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid)
                        let restDisplayStringMultifilter = resItem.DisplayStringMultiFiles.filter(d => d.source_no.includes(indexOfSource + 1));
                        let finalAns = restDisplayStringMultifilter.map(x => x.display_ans).join(' ')
                        _chatHistories[fileItem.guid].push({ "Question": resItem.Question, "Answer": finalAns })
                    })
                })
            } else {
                _chatHistories[sessionID] = [];
                _chatConversation = _chatConversation.filter(x => x.isMultiFiles === false);
                _chatConversation.map((resItem, resIndex) => {
                    let finalAns = resItem.DisplayString.map(x => x.display_ans).join(' ');
                    _chatHistories[sessionID].push({ "Question": resItem.Question, "Answer": finalAns })
                })
            }
        } catch (error) {
            console.log(error)
        }
        return _chatHistories;
    }
    processSourceSearchIds = (index) => {
        let { chatConversation } = this.state;
        let _sourceSearchIds = [];
        try {
            let _chatConversation = Object.assign([], chatConversation);
            if (index - 1 >= 0 && index - 1 < _chatConversation.length) {
                _sourceSearchIds = _chatConversation[index - 1].Sources.map(x => x.searchID);
            }
            _sourceSearchIds = uniq(_sourceSearchIds);
        } catch (error) {
            console.log(error)
        }
        return _sourceSearchIds;
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    runGenAISearch = () => {
        let { commandText, sessionID, chatHistory, isFirstQuestion, attachedFiles, threadID, selectedFilters, renderfilters, isMultiFileThread } = this.state;
        let _chatConversation = this.state.chatConversation;
        let _lastCreatedDatehour = getCurrentDate();
        let url = '';
        let _chatHistory = [];
        let query = commandText.trim().replace(/\n\n/g, '\n').split(/\n/g).filter(x => x);
        let _threadAttachedFiles = clone(attachedFiles.map(x => { return { name: x.name } }));
        url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_answers`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_answers`
        }
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
        this.setState({ isProcessing: true, spinnerMessage: "Please wait." })
        _chatHistory = this.processChatHistories(_chatConversation.length);
        let _sourceSearchId = this.processSourceSearchIds(_chatConversation.length);
        var config;
        let filterStr = "";
        let selectedFilter = [];
        var eventid = uuidv4();
        let _attachedFiles = attachedFiles.map(x => { return { name: x.name } })
        let _function = localStorage.getItem('function').replace(/"/g, '');
        if (userAccessGroup.includes("AllContent") && userAccessGroup.includes("MISEHCP")) {
            _function = `MISE;${localStorage.getItem('function').replace(/"/g, '')}`;
        }
        else if (userAccessGroup.includes("MISEHCP")) {
            _function = `MISE`;
        }
        else if (userAccessGroup.includes("AllContent")) {
            _function = localStorage.getItem('function').replace(/"/g, '');
        }
        Object.keys(GenAI2DocTypes).map(key => {
            selectedFilter.push(GenAI2DocTypes[key]);
        })
        filterStr = this.generateArrayString(selectedFilter);
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": query,
                "filters": filterStr,
                "function": _function,
                "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                "from": 0,
                "size": 7,
                "sortBy": "",
                "sortField": "",
                "defaultOperator": "or",
                "sessionID": sessionID,
                "threadID": threadID,
                "chat_history": _chatHistory,
                "sourceSearchId": _sourceSearchId,
                "isFirstQuestion": _chatConversation.length === 0,
                "IsComprehensive": false,
                "isMoreInformation": false,
            }
        }
        axios(config)
            .then(async (resp) => {

                query.map((questionItem, questionIndex) => {
                    let response = resp.data[questionIndex];
                    let isImageUpload = _attachedFiles.some(x =>
                        x.name.toLowerCase().endsWith('.gif')
                        || x.name.toLowerCase().endsWith('.jpeg')
                        || x.name.toLowerCase().endsWith('.jpg')
                        || x.name.toLowerCase().endsWith('.png'))

                    if (response.display_string_multi && response.display_string_multi.length > 0 || isImageUpload) {
                        _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: true, message: questionItem, messageTimeInText:  `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true, isFromOriginalText: false })
                        _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: true, OutputAnswer: response.output_answer, Sources: [], SourcesMultiFiles: response.sources_multi, DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: response.display_string_multi, RecommandationResults: [], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: response.combined_summary, threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true })
                        //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, response.rephrased_question, response.output_answer, [], [], [], _attachedFiles, response.sources_multi, response.display_string_multi, response.combined_summary, _lastCreatedDatehour, {}, query.length > 1, true, true);
                    } else {
                        _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: false, message: questionItem, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true, isFromOriginalText: false })
                        _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: false, Question: questionItem, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], SourcesMultiFiles: [], DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: [], RecommandationResults: [], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: false, isSuccess: true })
                        //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, questionItem, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, {}, query.length > 1, false, true);
                    }
                    eventid = uuidv4();
                })
            })
            .catch(async (error) => {
                query.map((questionItem, questionIndex) => {
                    _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: false, message: questionItem, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true, isFromOriginalText: false })
                    _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: false, Question: questionItem, OutputAnswer: "No Answer found. Please try a new question.", SourcesMultiFiles: [], DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("MMM DD , hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: false, isSuccess: false, isFromOriginalText: false })
                    //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, questionItem, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, {}, query.length > 1, false, false);
                    eventid = uuidv4();
                })
                console.log("error", error);
            })
            .finally(async () => {
                this.setState({
                    chatConversation: _chatConversation,
                    isProcessing: false,
                    commandText: '',
                    chatHistory: chatHistory,
                    isFirstQuestion: false,
                    renderfilters: renderfilters,
                    selectedFilters: selectedFilters,
                    spinnerMessage: ""
                }, async () => {
                    this.scrollToBottom();
                })
            });
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} style={{ marginBottom: "2px" }} /></>)
        } else if (name.toLowerCase().endsWith('.jpg')
            || name.toLowerCase().endsWith('.jpeg')
            || name.toLowerCase().endsWith('.png')
            || name.toLowerCase().endsWith('.gif')) {
            return (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5" stroke-width="0" fill="#059cf7" opacity="0.1"></polygon>
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5"></polygon>
                    <rect x="2" y="2" width="20" height="20" rx="0"></rect>
                    <line x1="6.99" y1="7" x2="7" y2="7" stroke-linecap="round" stroke-width="2"></line>
                </g>
            </svg> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }
    renderDisplayStringMultiFiles = (conversation) => {
        let { SourcesMultiFiles, Question, questionType, DisplayStringMultiFiles, OutputAnswer, isMultiFiles, eventid, threadAttachedFiles } = conversation;
        let { sessionID, threadID, attachedFiles, selectedAttachedFileguid } = this.state;
        if (DisplayStringMultiFiles.length > 0) {
            return (<div className="gen-ai-answering-result-main-answer-wrapper">{
                SourcesMultiFiles.map((smitem, smindex) => {
                    let filterDisplayStringMultiFiles = DisplayStringMultiFiles.filter(x => x.source_no.includes(smindex + 1));
                    return (<>
                        <div className="gen-ai-answering-result-main-answer-display-string-multi-files-document-title">
                            <b>
                                {this.renderFileIcon(smitem.document_title)}
                                {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" className="svg-inline--fa fa-file " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>*/}File: {smitem.document_title}</b>
                        </div>
                        {
                            filterDisplayStringMultiFiles.map((dsitem, dsindex) => {
                                dsitem.display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                                return (<GenAIAuthoringDisplayString key={`gen-ai-answering-result-main-answer${dsindex}`}
                                    displayAns={dsitem.display_ans} sourceNo={dsitem.source_no}
                                    contextIds={dsitem.context_id}
                                    pageNumber={dsitem.page_number} sources={SourcesMultiFiles}
                                    isMultiFiles={isMultiFiles} attachedFiles={attachedFiles}
                                    eventid={eventid}
                                    DisplayString={DisplayStringMultiFiles}
                                    handleClickOnCitationNumber={this.handleClickOnCitationNumber}
                                    sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                            })
                        }
                        {(SourcesMultiFiles.length > smindex + 1) && <hr style={{ margin: "10px 00px" }}></hr>}
                    </>)
                })
            }</div>)
        } else {
            let isImageUpload = threadAttachedFiles.some(x =>
                x.name.toLowerCase().endsWith('.gif')
                || x.name.toLowerCase().endsWith('.jpeg')
                || x.name.toLowerCase().endsWith('.jpg')
                || x.name.toLowerCase().endsWith('.png'))
            if (isImageUpload) {
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    <span dangerouslySetInnerHTML={{ __html: sanitize(`${OutputAnswer}`) }} />
                </div>)
            } else {
                if (OutputAnswer === "We don't know the answer. Can you try new questions.") {
                    OutputAnswer = `No Answer found. Please try a new question.`;
                }
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    {OutputAnswer}
                </div>)
            }
        }
    }
    renderChatConversation = () => {
        let { chatConversation, isProcessing, isShowMore, previewURL } = this.state;

        return (
            <>
                <div className="chat-body">
                    {chatConversation.map((conversation, conversationIndex) => {
                        if (conversation._commandType === "UserInstructions" && !conversation.isFromOriginalText) {
                            if (conversation.from === userQuestion) {
                                return (
                                    <AuthoringUserSummery key={`my-message-${conversationIndex}`} conversation={conversation} />
                                )
                            } else {
                                return (
                                    <div className="message info" key={`summery-message-${conversationIndex}`}>
                                        {/* <img alt='avatar' className="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png" /> */}
                                        <img alt='avatar' className="img-circle medium-image" src={robotHeadChatgpt} />
                                        <div className="message-body">
                                            <div className="message-text"   >
                                                {this.renderDisplayStringMultiFiles(conversation)}
                                            </div>
                                            <div className="message-info message-info-add-to-notes message-info-gpt-add-to-notes">
                                                <h5>{conversation.messageTimeInText} </h5>
                                                {/* {(conversation.IsSucceed) && <span title="Add to Notebook" className="add-to-notebook-action-text add-to-notebook-action-text-gpt" onClick={() => { previewURL ? this.handleAddToNotebookPreview(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) : this.handleAddToNotebook(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) }}>Add to Notebook</span>} */}
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }

                        } else {
                            if (conversation.from === userQuestion) {
                                return (
                                    <AuthoringUserSummery key={`my-message-${conversationIndex}`} conversation={conversation} />
                                )

                            } else {
                                return (
                                    <div className="message info" key={`summery-message-${conversationIndex}`}>
                                        {/* <img alt='avatar' className="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png" /> */}
                                        <img alt='avatar' className="img-circle medium-image" src={robotHeadChatgpt} />
                                        <div className="message-body">
                                            <div className="message-text" dangerouslySetInnerHTML={{ __html: sanitize(conversation.message) }} />
                                            <div className="message-info message-info-add-to-notes message-info-gpt-add-to-notes">
                                                <h5>{conversation.messageTimeInText} </h5>
                                                {/* {(conversation.IsSucceed && conversation.commandType !== 'CreateTable') && <span title="Add to Notes" className="add-to-notes-action-text" onClick={() => this.handleAddToNotes(conversation.message)}>Add to Notes</span>} */}
                                                {/* {(conversation.IsSucceed) && <span title="Add to Notebook" className="add-to-notebook-action-text add-to-notebook-action-text-gpt" onClick={() => { previewURL ? this.handleAddToNotebookPreview(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) : this.handleAddToNotebook(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) }}>Add to Notebook</span>} */}
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                        }
                    })
                    }
                    <div style={{ width: "100%" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>

            </>
        )
    }

    handleAddToConvertCitation = async () => {
        let { citationText, sessionID } = this.state;
        if (citationText) {
            var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP_AMA}ama_api2`
            if (window.location.origin === 'https://predev-ims.jnj.com') {
                url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_AMA}ama_api2`
            }
            //url = `https://predev-ims.jnj.com${process.env.REACT_APP_IMS_API_NLP_AMA}ama_api2`
            this.setState({ isCitationProcessing: true });
            let config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "orignalCitation": citationText,
                    "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                    "sessionID": sessionID,
                    "wwid": JSON.parse(localStorage.getItem('wwid')),
                }
            }
            await axios(config).then((response) => {
                if (response.data.AMACitation) {
                    this.setState({ citationText: response.data.AMACitation })
                    let requestTokenCount = 0, responseTokenCount = 0;
                    if (response.data.requestTokenCount)
                        requestTokenCount = response.data.requestTokenCount;
                    if (response.data.responseTokenCount)
                        responseTokenCount = response.data.responseTokenCount;
                    this.indexDataOnAMA(citationText, response.data.AMACitation, sessionID, requestTokenCount, responseTokenCount);
                }

            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.setState({ isCitationProcessing: false });
            });
        }
    };
    handleCitation = (e) => {
        this.setState({ citationText: e.target.value })
    };
    handleOnClickClearCitationExpand = () => {
        this.setState({ citationText: '' }, () => {
            this.successAlertMessage('Citation cleared successfully');
        })
    }
    handleSourceTextClick = () =>{
        let {commandType ,orignalText , openEditor} = this.state
        if(commandType !== 'CreateTable'){
            this.setState({openEditor: (!this.state.openEditor)})
        }
        }
        handleCloseSourceText = () =>{
            let { openEditor , orignalText ,commandType} = this.state;
            if(commandType === 'CreateTable'){
                this.setState({ orignalText:""})
                }
                else{
            this.setState({openEditor: (!this.state.openEditor),orignalText: ''})
                }
            }    

    render() {
        const { showDisclaimerPopup, collapsed, commandText, commandType, citationText, isCitationProcessing, attachedFiles, expandCommand } = this.state;
        const { selectedAttachedFileguid, selectedPageNo, isTemplateCommand, isUserCommand, isFromGlobalFeaturesMenu, showCommandList, spinnerMessage } = this.state;
        const { isGraph, selectedGraph, orignalText, isChangePage } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-authoring-container">
                <div className="gen-ai-answering-authoring-top">
                    <div className="gen-ai-answering-authoring-img-wrapper">
                        <img
                            src={chatbotHeaderIcon}
                            alt="iMS profile image"
                            className="gen-ai-answering-authoring-img"
                            style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                            onClick={() => {
                                let data = {
                                    sessionID: this.state.sessionID,
                                    question: this.state.question,
                                    attachedFiles: this.state.attachedFiles,
                                    threadID: this.state.threadID,
                                };
                                this.props.history.push({
                                    pathname: '/genAIAnswering',
                                    state: data // your data array of objects
                                })
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <a style={{ marginTop: '4px' }} className="authoring-model-body-content-link" onClick={() => { this.setState({ showDisclaimerPopup: true }) }} >Gen Ai Adherence Policy</a>
                        <div style={{ cursor: "pointer", marginLeft: '15px' }} onClick={() => {
                            let data = {
                                sessionID: this.state.sessionID,
                                question: this.state.question,
                                attachedFiles: this.state.attachedFiles,
                                threadID: this.state.threadID,
                            };
                            this.props.history.push({
                                pathname: '/genAIAnswering',
                                state: data // your data array of objects
                            })
                        }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="30px" height="30px">
                                <path fill="currentColor" d="M 64 6 C 48.5 6 33.9 12 23 23 C 12 33.9 6 48.5 6 64 C 6 79.5 12 94.1 23 105 C 34 116 48.5 122 64 122 C 79.5 122 94.1 116 105 105 C 116 94 122 79.5 122 64 C 122 48.5 116 33.9 105 23 C 94.1 12 79.5 6 64 6 z M 64 12 C 77.9 12 90.900781 17.399219 100.80078 27.199219 C 110.70078 36.999219 116 50.1 116 64 C 116 77.9 110.60078 90.900781 100.80078 100.80078 C 90.900781 110.60078 77.9 116 64 116 C 50.1 116 37.099219 110.60078 27.199219 100.80078 C 17.299219 91.000781 12 77.9 12 64 C 12 50.1 17.399219 37.099219 27.199219 27.199219 C 36.999219 17.299219 50.1 12 64 12 z M 50.5625 47.5 C 49.8 47.5 49.05 47.800391 48.5 48.400391 C 47.3 49.600391 47.3 51.499609 48.5 52.599609 L 59.800781 64 L 48.400391 75.300781 C 47.200391 76.500781 47.200391 78.4 48.400391 79.5 C 49.000391 80.1 49.8 80.400391 50.5 80.400391 C 51.2 80.400391 51.999609 80.1 52.599609 79.5 L 64 68.199219 L 75.300781 79.5 C 75.900781 80.1 76.700391 80.400391 77.400391 80.400391 C 78.100391 80.400391 78.9 80.1 79.5 79.5 C 80.7 78.3 80.7 76.400781 79.5 75.300781 L 68.199219 64 L 79.5 52.699219 C 80.7 51.499219 80.699609 49.600391 79.599609 48.400391 C 78.399609 47.200391 76.500391 47.200391 75.400391 48.400391 L 64 59.800781 L 52.699219 48.400391 C 52.099219 47.800391 51.325 47.5 50.5625 47.5 z" />
                            </svg></div></div>
                </div>
                <div className="gen-ai-answering-authoring-bottom">
                    <GenAIAuthoringSidebar isChangePage={isChangePage} selectedAttachedFileguid={selectedAttachedFileguid} selectedPageNo={selectedPageNo} attachedFiles={attachedFiles} handleChangefiles={this.handleChangefiles} customWidth={this.state.customWidth} customHeight={this.state.customHeight} handleCollapsedClick={this.handleCollapsedClick} />
                    <div className={collapsed ? "gen-ai-answering-authoring-right gen-ai-answering-authoring-right-collapsed" : "gen-ai-answering-authoring-right gen-ai-answering-authoring-right-expand"}>
                        <div className="answering-authoring-summery-result-wrapper-gpt">
                            <div className="answering-authoring-result-label-gpt">
                                <span>Results</span>
                            </div>
                            <div className="answering-authoring-result-panel-gpt">
                                {this.renderChatConversation()}
                            </div>
                        </div>
                        <div className="answering-authoring-source-result-wrapper-gpt">
                            <div className="answering-authoring-source-label-gpt">
                                <span onClick={() => this.handleSourceTextClick()}>+Source Text</span>
                                {(this.state.openEditor||(commandType === 'CreateTable')) && <span className='GenAI-text-area-crossIcon'>
                                    <img src={crossIcon} width={12} height={12} alt="Cross" onClick={() => this.handleCloseSourceText()} />
                                </span>}
                            </div>
                        </div>
                        {(this.state.openEditor || (commandType === 'CreateTable')) ? <div className="answering-authoring-source-command-citation" >
                            <div className="genAI-top-editor">
                                <textarea className="GenAI-text-area" name="exportText" value={orignalText}
                                    onChange={(e) => this.handleSummery(e)} ref={(input) => { this.sourceTextInput = input; }}
                                    placeholder="Enter the source text here."></textarea></div>
                            <div className="answering-authoring-summery-comment-send-save-notebook-wrapper-gpt">
                                <div className="answering-authoring-command-label-gpt">
                                    <span>Command</span>
                                    <button title="Command Expand" class="btn-like-expandAll" onClick={e => {
                                        this.handleOnClickcommandTextExpand()
                                    }}><img src={expandAllBlueIcon} alt="Expand" />
                                    </button>
                                </div>
                                <div className="answering-authoring-command-wrapper-panel-gpt">
                                    <div className="answering-authoring-command-dropdown-panel-gpt">
                                        <select
                                            className="answering-authoring-command-dropdown-gpt"
                                            onChange={this.handleChangeCommandType}
                                            value={commandType}>
                                            <option value="UserInstructions">User Instructions​</option>
                                            <option value="CreateTable">Create Table​</option>
                                            <option value="Summarize">Summarize</option>
                                            <option value="Translate">Translate</option>
                                            { /*<option value="Graph">Create Graph</option>*/}
                                            {/* <option value="QnA">QnA</option> */}
                                        </select>
                                        {/*{(selectedGraph) && <div className='select-graph-wrapper'>
                                        <span>{selectedGraph}</span>
                                       <span onClick={()=>this.setState({isGraph: !(isGraph)})}><svg width="16px" height="14px" style={{ marginTop: "2px" }} 
                                        viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg" fill="black">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0">
                                                </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" 
                                                stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                                                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="black"></path></g>
                                                    </svg></span>
                                        </div>}*/}
                                    </div>
                                    {/*{(isGraph) && <div>
                                        <ul className="graph-sub-wrapper-menu">
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Scatter Plot'})
                                        }}><img className ="menu-image"src={LineScatterGraph}/>Scatter Plot</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Line Charts'})
                                            }}><img className ="menu-image" src ={LinePlot}/>Line Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bar Chart'})
                                            }}><img className ="menu-image"src={BarGraph}/>Bar Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Pie Chart'})
                                            }}><img className ="menu-image" src ={PieGraph}/>Pie Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bubble Chart'})
                                            }}><img className ="menu-image" src ={BubbleGraph}/>Bubble Charts</li>
                                            </ul></div>}*/}

                                    <div className="answering-authoring-summery-comment-send-gpt">
                                        <div className="answering-authoring-comment-panel-gpt">
                                            <textarea name="commandText" value={commandText} placeholder="Type Additional Commands here." onChange={(e) => this.handleComment(e)} />
                                            <span title="Save Command" className="add-to-command-action-text add-to-command-action-save" onClick={() => this.handleAddToCommand()}>Save Command</span>
                                        </div>
                                        <div className="answering-authoring-send-panel-gpt">
                                            <button className="btnNotebook" type="button" onClick={(e) => this.onSubmitClick()}  >Submit</button>
                                            <span title="My Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnMyCommand()}>My Commands</span>
                                            <span title="Template Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnTemplateCommand()}>Template Commands</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="answering-authoring-summery-citation-wrapper-gpt">
                                <div className="answering-authoring-gpt-citation-label">
                                    <span>Citation</span>
                                </div>
                                <div className="answering-authoring-gpt-summery-citation-send">
                                    {isCitationProcessing ? <>
                                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                        </div>
                                    </> : <>
                                        <textarea name="citationText" value={citationText} placeholder="Enter your citation here." onChange={(e) => this.handleCitation(e)} />
                                        <div className="add-to-citation-action-text add-to-command-gpt-action-save add-to-command-action-gpt">
                                            <span className="answering-authoring-summery-gpt-citation-link" title="Convert Citation" onClick={() => this.handleAddToConvertCitation()}>Convert Citation</span>
                                            <span className="answering-authoring-summery-citation-seprater"></span>
                                            <span className="answering-authoring-summery-gpt-citation-link" title="Clear Citation" onClick={this.handleOnClickClearCitationExpand}>Clear Citation</span>
                                        </div>
                                    </>}

                                </div>
                            </div>
                        </div> : <>
                            <div className="answering-authoring-summery-comment-send-save-notebook-wrapper-gpt">
                                <div className="answering-authoring-command-label-gpt">
                                    <span>Command</span>
                                    <button title="Command Expand" class="btn-like-expandAll" onClick={e => {
                                        this.handleOnClickcommandTextExpand()
                                    }}><img src={expandAllBlueIcon} alt="Expand" />
                                    </button>
                                </div>
                                <div className="answering-authoring-command-wrapper-panel-gpt">
                                    <div className="answering-authoring-command-dropdown-panel-gpt">
                                        <select
                                            className="answering-authoring-command-dropdown-gpt"
                                            onChange={this.handleChangeCommandType}
                                            value={commandType}>
                                            <option value="UserInstructions">User Instructions​</option>
                                            <option value="CreateTable">Create Table​</option>
                                            <option value="Summarize">Summarize</option>
                                            <option value="Translate">Translate</option>
                                            { /*<option value="Graph">Create Graph</option>*/}
                                            {/* <option value="QnA">QnA</option> */}
                                        </select>
                                        {/*{(selectedGraph) && <div className='select-graph-wrapper'>
                                        <span>{selectedGraph}</span>
                                       <span onClick={()=>this.setState({isGraph: !(isGraph)})}><svg width="16px" height="14px" style={{ marginTop: "2px" }} 
                                        viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg" fill="black">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0">
                                                </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" 
                                                stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                                                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="black"></path></g>
                                                    </svg></span>
                                        </div>}*/}
                                    </div>
                                    {/*{(isGraph) && <div>
                                        <ul className="graph-sub-wrapper-menu">
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Scatter Plot'})
                                        }}><img className ="menu-image"src={LineScatterGraph}/>Scatter Plot</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Line Charts'})
                                            }}><img className ="menu-image" src ={LinePlot}/>Line Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bar Chart'})
                                            }}><img className ="menu-image"src={BarGraph}/>Bar Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Pie Chart'})
                                            }}><img className ="menu-image" src ={PieGraph}/>Pie Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bubble Chart'})
                                            }}><img className ="menu-image" src ={BubbleGraph}/>Bubble Charts</li>
                                            </ul></div>}*/}

                                    <div className="answering-authoring-summery-comment-send-gpt">
                                        <div className="answering-authoring-comment-panel-gpt">
                                            <textarea name="commandText" value={commandText} placeholder="Type Additional Commands here." onChange={(e) => this.handleComment(e)} />
                                            <span title="Save Command" className="add-to-command-action-text add-to-command-action-save" onClick={() => this.handleAddToCommand()}>Save Command</span>
                                        </div>
                                        <div className="answering-authoring-send-panel-gpt">
                                            <button className="btnNotebook" type="button" onClick={(e) => this.onSubmitClick()}  >Submit</button>
                                            <span title="My Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnMyCommand()}>My Commands</span>
                                            <span title="Template Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnTemplateCommand()}>Template Commands</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="answering-authoring-summery-citation-wrapper-gpt">
                                <div className="answering-authoring-gpt-citation-label">
                                    <span>Citation</span>
                                </div>
                                <div className="answering-authoring-gpt-summery-citation-send">
                                    {isCitationProcessing ? <>
                                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                        </div>
                                    </> : <>
                                        <textarea name="citationText" value={citationText} placeholder="Enter your citation here." onChange={(e) => this.handleCitation(e)} />
                                        <div className="add-to-citation-action-text add-to-command-gpt-action-save add-to-command-action-gpt">
                                            <span className="answering-authoring-summery-gpt-citation-link" title="Convert Citation" onClick={() => this.handleAddToConvertCitation()}>Convert Citation</span>
                                            <span className="answering-authoring-summery-citation-seprater"></span>
                                            <span className="answering-authoring-summery-gpt-citation-link" title="Clear Citation" onClick={this.handleOnClickClearCitationExpand}>Clear Citation</span>
                                        </div>
                                    </>}

                                </div>
                            </div></>}
                    </div>
                </div>
                {showDisclaimerPopup && <GenAIAnsweringDisclaimer showPopup={showDisclaimerPopup} handleDisclaimerClose={this.handleDisclaimerClose} />}
                {expandCommand && <ExpandContentPopUpModal placeholder='Enter your command here.' content={commandText} showModal={expandCommand} handleSaveContent={this.handleCommandTextExpand} />}
                {showCommandList && <CommandListPopUp showModal={showCommandList} handleClose={this.handleCloseCommandList}
                    width={this.props.customWidth} commandText={commandText} handleAddToCommandText={this.handleAddToCommandText}
                    isUserCommand={isUserCommand} isTemplateCommand={isTemplateCommand} isFromGlobalFeaturesMenu={isFromGlobalFeaturesMenu}
                />}
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="gen-ai-answering-search-spinner-popup"
                        backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while finding answers.`} </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>)
        }
    }

}
export default withRouter(GenAIAuthoringIndex);